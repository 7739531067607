import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API_BASE_URL } from '../config/api';
import { attachPaymentMethod } from '../pages/billing/BillingUtils';

// Initialize Stripe
const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY 
  ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
  : Promise.reject(new Error('Stripe public key not found'));

const PaymentFormContent = ({ email, onSuccess, onError, mode = 'credit', amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [cardError, setCardError] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');

  useEffect(() => {
    // Log environment variables when component mounts
    if (mode === 'subscription') {
      console.log('Stripe configuration:', {
        monthlyPriceId: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
        yearlyPriceId: process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID,
        publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY ? 'present' : 'missing'
      });
    }
  }, [mode]);

  const handleCardChange = (event) => {
    try {
      if (event.error) {
        setCardError(event.error.message);
        // Only propagate actual errors, not validation messages
        if (event.error.type === 'validation_error') {
          return;
        }
        onError(event.error.message);
      } else {
        setCardError('');
      }
    } catch (err) {
      console.error('Error in handleCardChange:', err);
      setCardError('An error occurred while processing your card. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setCardError('');
    setCouponError('');

    if (!stripe || !elements) {
      onError('Payment system not initialized. Please try again later.');
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      onError('Card element not found.');
      setLoading(false);
      return;
    }

    try {
      // Create payment method
      const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { email }
      });

      if (paymentMethodError) {
        throw new Error(paymentMethodError.message);
      }

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');

      if (mode === 'credit') {
        // First save the payment method
        await attachPaymentMethod(userId, token, paymentMethod.id);

        // Then purchase credit
        const response = await fetch(`${API_BASE_URL}/api/stripe/purchase-credit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            amount: parseFloat(amount),
            payment_method_id: paymentMethod.id,
            user_id: parseInt(userId),
            coupon_code: couponCode || undefined
          }),
        });

        if (!response.ok) {
          const data = await response.json();
          if (data.detail?.includes('coupon')) {
            setCouponError(data.detail);
          }
          throw new Error(data.detail || 'Failed to purchase credit');
        }

        const data = await response.json();
        onSuccess(data);
      } else if (mode === 'setup') {
        // Just save the payment method
        await attachPaymentMethod(userId, token, paymentMethod.id);
        onSuccess();
      } else {
        // Create subscription (for signup flow)
        const priceId = selectedPlan === 'monthly' 
          ? process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID
          : process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID;

        if (!priceId) {
          console.error('Price ID not found:', {
            selectedPlan,
            monthlyPriceId: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
            yearlyPriceId: process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID
          });
          throw new Error(`Price ID not found for ${selectedPlan} plan. Please try again later.`);
        }

        const requestBody = {
          payment_method_id: paymentMethod.id,
          email: email,
          price_id: priceId,
          coupon_code: couponCode || undefined
        };

        console.log('Creating subscription with:', requestBody);

        const response = await fetch(`${API_BASE_URL}/api/stripe/create-subscription`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody)
        });

        const data = await response.json();
        console.log('Subscription response:', data);

        if (!response.ok) {
          if (response.status === 422) {
            console.error('Validation error:', data);
            const errorDetail = data.detail?.[0]?.msg || data.detail || 'Invalid request data';
            if (errorDetail.includes('coupon')) {
              setCouponError(errorDetail);
            }
            throw new Error(errorDetail);
          }
          throw new Error(data.detail || 'Failed to create subscription');
        }

        // For trial subscriptions, we don't need to confirm the payment
        // since there's no immediate charge
        if (data.clientSecret) {
          // If there's a payment to confirm
          const { error: confirmError } = await stripe.confirmCardPayment(data.clientSecret);
          if (confirmError) {
            throw new Error(confirmError.message);
          }
        }

        onSuccess(data);
      }
    } catch (error) {
      console.error('Payment error:', error);
      if (!couponError) {
        setCardError(error.message || 'An error occurred while processing your payment.');
      }
      onError(error.message || 'An error occurred while processing your payment.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      {mode === 'subscription' && (
        <>
          <h4 className="section-title">Select Your Plan</h4>
          <div className="plan-selection">
            <div 
              className={`plan-option ${selectedPlan === 'monthly' ? 'selected' : ''}`}
              onClick={() => setSelectedPlan('monthly')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => e.key === 'Enter' && setSelectedPlan('monthly')}
            >
              <h4>Monthly Plan</h4>
              <div className="price">${process.env.REACT_APP_STRIPE_MONTHLY_PRICE}/mo</div>
              <div className="trial-note">7-day free trial</div>
              {selectedPlan === 'monthly' && <div className="selected-indicator">✓ Selected</div>}
            </div>
            <div 
              className={`plan-option ${selectedPlan === 'yearly' ? 'selected' : ''}`}
              onClick={() => setSelectedPlan('yearly')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => e.key === 'Enter' && setSelectedPlan('yearly')}
            >
              <h4>Annual Plan</h4>
              <div className="price">${process.env.REACT_APP_STRIPE_YEARLY_PRICE}/yr</div>
              <div className="savings">Save 17%</div>
              <div className="trial-note">7-day free trial</div>
              {selectedPlan === 'yearly' && <div className="selected-indicator">✓ Selected</div>}
            </div>
          </div>
        </>
      )}

      <div className="card-element-wrapper" style={{ marginBottom: '2rem' }}>
        {mode === 'setup' ? (
          <h4 className="section-title">Add New Payment Method</h4>
        ) : (
          <h4 className="section-title">Payment Information</h4>
        )}
        
        {mode === 'subscription' ? (
          <p className="trial-info">
            Start your 7-day free trial today. Your card won't be charged until the trial ends.
            You can cancel anytime during the trial period.
          </p>
        ) : mode === 'credit' ? (
          <p className="purchase-info">
            Purchase ${amount} in credits
          </p>
        ) : (
          <p className="setup-info">
            Add a new payment method to your account
          </p>
        )}

        {/* Coupon Code Input */}
        <div className="coupon-field-group" style={{ marginBottom: '1rem' }}>
          <label className="coupon-label" style={{ display: 'block', marginBottom: '0.5rem' }}>
            Have a coupon code?
            <input
              type="text"
              value={couponCode}
              onChange={(e) => {
                setCouponCode(e.target.value);
                setCouponError('');
              }}
              placeholder="Enter coupon code"
              style={{
                width: '100%',
                padding: '0.75rem',
                borderRadius: '8px',
                border: '1px solid #e2e8f0',
                marginTop: '0.5rem',
                fontSize: '16px'
              }}
            />
          </label>
          {couponError && (
            <div style={{ 
              color: '#e53e3e', 
              marginTop: '0.5rem', 
              fontSize: '0.875rem',
              padding: '0.5rem',
              background: 'rgba(229, 62, 62, 0.1)',
              borderRadius: '4px'
            }}>
              {couponError}
            </div>
          )}
        </div>
        
        <div className="card-field-group">
          <label className="card-label">
            Enter your card details below
            <div className="card-element-container" style={{
              background: 'white',
              padding: '1rem',
              borderRadius: '8px',
              marginTop: '0.5rem',
              minHeight: '40px'
            }}>
              <CardElement 
                onChange={handleCardChange}
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                      lineHeight: '24px'
                    },
                    invalid: {
                      color: '#e53e3e',
                    },
                  },
                  hidePostalCode: true,
                  hideIcon: true,
                }}
              />
            </div>
          </label>
          {cardError && (
            <div style={{ 
              color: '#e53e3e', 
              marginTop: '0.5rem', 
              fontSize: '0.875rem',
              padding: '0.5rem',
              background: 'rgba(229, 62, 62, 0.1)',
              borderRadius: '4px'
            }}>
              {cardError}
            </div>
          )}
        </div>
      </div>

      <button 
        type="submit" 
        disabled={!stripe || loading}
        style={{
          background: '#22cd94',
          border: 'none',
          borderRadius: '12px',
          padding: '0.75rem 1.5rem',
          color: 'white',
          cursor: loading ? 'not-allowed' : 'pointer',
          fontSize: '1rem',
          fontWeight: '600',
          width: '100%',
          opacity: loading ? 0.7 : 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '0.5rem'
        }}
      >
        {loading ? (
          <>
            <span className="loading-spinner"></span>
            Processing...
          </>
        ) : (
          mode === 'subscription' ? 'Start Free Trial' : 
          mode === 'credit' ? 'Purchase Credits' :
          'Add Payment Method'
        )}
      </button>
    </form>
  );
};

const PaymentForm = (props) => {
  if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        <p style={{ color: '#e53e3e' }}>Payment system is currently unavailable. Please try again later.</p>
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <PaymentFormContent {...props} />
    </Elements>
  );
};

export default PaymentForm;
