import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config/api';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); // 1: email input, 2: code verification and new password
  const [resendDisabled, setResendDisabled] = useState(false);
  const navigate = useNavigate();

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/password-reset/request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (data.success) {
        setStep(2);
        setSuccessMessage('Reset code sent to your email');
      } else {
        throw new Error(data.message || 'Failed to send reset code');
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    if (resendDisabled) return;
    
    setLoading(true);
    setError('');
    setSuccessMessage('');
    setResendDisabled(true);

    try {
      const response = await fetch(`${API_BASE_URL}/api/password-reset/resend-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (data.success) {
        setSuccessMessage('New reset code sent to your email');
        // Enable resend button after 1 minute
        setTimeout(() => setResendDisabled(false), 60000);
      } else {
        throw new Error(data.message || 'Failed to resend code');
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
      setResendDisabled(false);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/password-reset/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          code,
          new_password: newPassword,
        }),
      });

      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Password reset successful! Redirecting to login...');
        // Add delay before redirect for user to see success message
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        throw new Error(data.message || 'Failed to reset password');
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="form-column">
        <div className="form-wrapper">
          <div className="form-logo-wrapper">
            <img src="/images/3.png" alt="SalesOptAI" className="form-logo" style={{ height: 'auto', maxHeight: '100px' }} />
          </div>

          <h2 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
            {step === 1 ? 'Reset Password' : 'Enter Reset Code'}
          </h2>
          
          {error && (
            <div className="error" style={{
              background: 'rgba(229, 62, 62, 0.1)',
              border: '1px solid rgba(229, 62, 62, 0.2)',
              padding: '12px 16px',
              borderRadius: '8px',
              marginBottom: '1rem',
              color: '#e53e3e',
              textAlign: 'center'
            }}>
              {error}
            </div>
          )}

          {successMessage && (
            <div style={{
              background: 'rgba(72, 187, 120, 0.1)',
              border: '1px solid rgba(72, 187, 120, 0.2)',
              padding: '12px 16px',
              borderRadius: '8px',
              marginBottom: '1rem',
              color: '#48bb78',
              textAlign: 'center'
            }}>
              {successMessage}
            </div>
          )}
          
          {step === 1 ? (
            <form className="assistant-form" onSubmit={handleRequestReset} style={{ maxWidth: '500px', width: '100%' }}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                disabled={loading}
                style={{ maxWidth: '500px', width: '100%' }}
              />
              
              <button type="submit" className="btn" disabled={loading} style={{ maxWidth: '500px', width: '100%' }}>
                {loading ? 'Sending...' : 'Send Reset Code'}
              </button>
            </form>
          ) : (
            <form className="assistant-form" onSubmit={handleResetPassword} style={{ maxWidth: '500px', width: '100%' }}>
              <div style={{ position: 'relative', width: '100%', marginBottom: '1rem' }}>
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Reset Code"
                  required
                  disabled={loading}
                  style={{ maxWidth: '500px', width: '100%' }}
                />
                <button
                  type="button"
                  onClick={handleResendCode}
                  disabled={loading || resendDisabled}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    color: 'var(--accent)',
                    cursor: resendDisabled ? 'not-allowed' : 'pointer',
                    opacity: resendDisabled ? 0.5 : 1,
                    padding: '5px',
                    fontSize: '0.9em'
                  }}
                >
                  Resend Code
                </button>
              </div>
              
              <div style={{ position: 'relative', maxWidth: '500px', width: '100%' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New Password"
                  required
                  disabled={loading}
                  style={{ paddingRight: '50px', maxWidth: '500px', width: '100%' }}
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    fontSize: '12px',
                    color: 'var(--accent)'
                  }}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </span>
              </div>

              <input
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm New Password"
                required
                disabled={loading}
                style={{ maxWidth: '500px', width: '100%' }}
              />
              
              <button type="submit" className="btn" disabled={loading} style={{ maxWidth: '500px', width: '100%' }}>
                {loading ? 'Resetting...' : 'Reset Password'}
              </button>
            </form>
          )}
          
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <Link to="/login" style={{ color: 'var(--accent)' }}>Back to Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
