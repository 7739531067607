import React, { useState } from 'react';
import { Clock, ExternalLink } from 'react-feather';
import { formatDate } from './BillingUtils';

// Transaction Modal Component
const TransactionModal = ({ transaction, onClose }) => {
  if (!transaction) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div style={{
        background: '#1a1a1a',
        padding: '2rem',
        borderRadius: '12px',
        width: '90%',
        maxWidth: '500px',
        maxHeight: '90vh',
        overflowY: 'auto',
        position: 'relative'
      }}>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            background: 'none',
            border: 'none',
            color: 'white',
            fontSize: '1.5rem',
            cursor: 'pointer'
          }}
        >
          ×
        </button>
        
        <h2 style={{ color: 'white', marginBottom: '1.5rem' }}>Transaction Details</h2>
        
        <div style={{ marginBottom: '1rem' }}>
          <p style={{ color: 'white', marginBottom: '0.5rem' }}>
            <strong>Amount:</strong> ${transaction.amount.toFixed(2)}
          </p>
          <p style={{ color: 'white', marginBottom: '0.5rem' }}>
            <strong>Status:</strong> <span style={{
              color: transaction.status === 'succeeded' ? '#22cd94' : '#ff4444',
              textTransform: 'capitalize'
            }}>{transaction.status}</span>
          </p>
          <p style={{ color: 'white', marginBottom: '0.5rem' }}>
            <strong>Date:</strong> {formatDate(transaction.created)}
          </p>
          <p style={{ color: 'white', marginBottom: '0.5rem' }}>
            <strong>Description:</strong> {transaction.description || 'N/A'}
          </p>
          {transaction.payment_method_details && (
            <p style={{ color: 'white', marginBottom: '0.5rem' }}>
              <strong>Payment Method:</strong> {transaction.payment_method_details.card.brand.toUpperCase()} •••• {transaction.payment_method_details.card.last4}
            </p>
          )}
        </div>

        {transaction.receipt_url && (
          <a
            href={transaction.receipt_url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '0.5rem',
              background: '#22cd94',
              color: 'white',
              padding: '0.75rem 1rem',
              borderRadius: '8px',
              textDecoration: 'none',
              marginTop: '1rem'
            }}
          >
            <ExternalLink size={16} />
            View Receipt
          </a>
        )}
      </div>
    </div>
  );
};

const TransactionHistory = ({ transactions = [] }) => { // Provide default empty array
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  return (
    <div style={{
      background: 'rgba(255, 255, 255, 0.08)',
      borderRadius: '12px',
      padding: '1.5rem',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
    }}>
      {transactions.length > 0 ? (
        <div>
          {transactions.map((transaction) => (
            <div
              key={transaction.id}
              onClick={() => setSelectedTransaction(transaction)}
              style={{
                background: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '8px',
                padding: '1rem',
                marginBottom: '1rem',
                cursor: 'pointer',
                transition: 'background-color 0.2s',
                ':hover': {
                  background: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ color: 'white' }}>
                  <div style={{ fontSize: '1.1rem', marginBottom: '0.2rem' }}>
                    ${transaction.amount.toFixed(2)} - {transaction.description || 'Credit Purchase'}
                  </div>
                  <div style={{ fontSize: '0.9rem', opacity: 0.7 }}>
                    {formatDate(transaction.created)}
                  </div>
                </div>
                <div style={{
                  color: transaction.status === 'succeeded' ? '#22cd94' : '#ff4444',
                  textTransform: 'capitalize',
                  fontSize: '0.9rem'
                }}>
                  {transaction.status}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <Clock style={{ color: 'white', width: '2rem', height: '2rem' }} />
          <div>
            <h3 style={{ margin: 0, fontSize: '1.3rem', color: 'white' }}>Recent Transactions</h3>
            <p style={{ margin: '0.5rem 0 0 0', opacity: 0.9, color: '#f0f0f0' }}>No recent transactions to display</p>
          </div>
        </div>
      )}

      {/* Transaction Modal */}
      {selectedTransaction && (
        <TransactionModal
          transaction={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
        />
      )}
    </div>
  );
};

export default TransactionHistory;
