import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import MobileMenu from './MobileMenu';

const AuthLayout = () => {
  return (
    <div className="app-container">
      <Sidebar />
      <MobileMenu />
      <main className="main-content">
        <Outlet />
      </main>
    </div>
  );
};

export default AuthLayout;
