export const formatPhoneNumber = (value) => {
  // Remove all non-digit characters except +
  let cleaned = value.replace(/[^\d+]/g, '');
  
  // Ensure it starts with +1
  if (!cleaned.startsWith('+')) {
    cleaned = '+' + cleaned;
  }
  if (cleaned.startsWith('+') && !cleaned.startsWith('+1') && cleaned.length > 1) {
    cleaned = '+1' + cleaned.substring(1);
  }
  
  // Format the number: +1 (XXX) XXX-XXXX
  if (cleaned.length > 2) {
    let formatted = cleaned.substring(0, 2); // +1
    const remaining = cleaned.substring(2);
    
    if (remaining.length > 0) {
      formatted += ' (';
      formatted += remaining.substring(0, 3);
      if (remaining.length > 3) {
        formatted += ') ';
        formatted += remaining.substring(3, 6);
        if (remaining.length > 6) {
          formatted += '-';
          formatted += remaining.substring(6, 10);
        }
      }
    }
    return formatted;
  }
  
  return cleaned;
};
