import React, { useState } from 'react';
import { CreditCard, Star, Trash2 } from 'react-feather';
import PaymentForm from '../../components/PaymentForm';
import { setDefaultPaymentMethod, deletePaymentMethod } from './BillingUtils';
import './billing.css';

const PaymentMethods = ({ 
  paymentMethods = [],
  loading = false,
  onPaymentMethodsChange,
  onError 
}) => {
  const [addingNewCard, setAddingNewCard] = useState(false);

  const handleSetDefaultPaymentMethod = async (paymentMethodId) => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      await setDefaultPaymentMethod(userId, token, paymentMethodId);
      await onPaymentMethodsChange();
    } catch (err) {
      onError('Failed to set default payment method');
      console.error('Error setting default payment method:', err);
    }
  };

  const handleDeletePaymentMethod = async (paymentMethodId) => {
    if (!window.confirm('Are you sure you want to delete this payment method?')) {
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      await deletePaymentMethod(userId, token, paymentMethodId);
      await onPaymentMethodsChange();
    } catch (err) {
      onError('Failed to delete payment method');
      console.error('Error deleting payment method:', err);
    }
  };

  const handleNewCardSuccess = async () => {
    await onPaymentMethodsChange();
    setAddingNewCard(false);
  };

  return (
    <div className="payment-methods-card">
      <div className="card-header">
        <h2 className="card-title">Payment Methods</h2>
        <button 
          onClick={() => setAddingNewCard(true)}
          className="add-card-button"
        >
          <CreditCard size={16} />
          Add New Card
        </button>
      </div>

      {addingNewCard && (
        <div className="new-card-form">
          <PaymentForm
            mode="setup"
            onSuccess={handleNewCardSuccess}
            onError={(error) => {
              onError(error);
              setAddingNewCard(false);
            }}
          />
        </div>
      )}

      {paymentMethods.map((method) => (
        <div
          key={method.id}
          className="payment-method-item"
        >
          <div className="payment-method-info">
            <div className="payment-method-details">
              <CreditCard size={16} />
              {method.brand.toUpperCase()} •••• {method.last4}
              {method.is_default && (
                <span className="default-badge">
                  Default
                </span>
              )}
            </div>
            <div className="expiry-date">
              Expires {method.exp_month}/{method.exp_year}
            </div>
          </div>
          
          <div className="payment-method-actions">
            {!method.is_default && (
              <button
                onClick={() => handleSetDefaultPaymentMethod(method.id)}
                className="action-button default-button"
                title="Set as Default"
              >
                <Star size={16} />
              </button>
            )}
            <button
              onClick={() => handleDeletePaymentMethod(method.id)}
              className="action-button delete-button"
              title="Delete"
              disabled={method.is_default}
            >
              <Trash2 size={16} />
            </button>
          </div>
        </div>
      ))}

      {paymentMethods.length === 0 && !addingNewCard && (
        <p className="no-methods-message">
          No payment methods added yet
        </p>
      )}
    </div>
  );
};

export default PaymentMethods;
