import React from 'react';
import Sidebar from './Sidebar';
import LoadingSpinner from './LoadingSpinner';

const PageContainer = ({ 
  children, 
  loading, 
  loadingMessage,
  title,
  subtitle,
  onLogout 
}) => {
  return (
    <div className="page-root">
      <Sidebar onLogout={onLogout} />
      <div className="dashboard-container">
        {!loading && (
          <div className="dashboard-header">
            <div>
              <h1 className="dashboard-title">{title}</h1>
              {subtitle && (
                <p className="dashboard-subtitle">{subtitle}</p>
              )}
            </div>
          </div>
        )}
        
        <div className="dashboard-content">
          {loading ? (
            <div className="loading-container">
              <LoadingSpinner message={loadingMessage} />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

export default PageContainer;
