import React, { useState } from 'react';
import { AlertTriangle } from 'react-feather';
import { API_BASE_URL } from '../../config/api';
import './billing.css';

const CancelAccount = ({ onError }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelAccount = async () => {
    try {
      setIsLoading(true);
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      
      const response = await fetch(`${API_BASE_URL}/api/stripe/subscription/${userId}/cancel-account`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to cancel account');
      }

      const data = await response.json();
      
      if (data.immediate_cancellation) {
        // If trial period, redirect to login since they can't use the account anymore
        window.location.href = '/login';
      } else {
        // If paid period, refresh the page to show updated subscription status
        window.location.reload();
      }
    } catch (error) {
      console.error('Error canceling account:', error);
      onError('Failed to cancel account. Please try again.');
    } finally {
      setIsLoading(false);
      setShowConfirmation(false);
    }
  };

  return (
    <div className="cancel-account-section">
      <h2 className="section-title">
        <AlertTriangle className="section-icon" />
        Cancel Account
      </h2>
      
      <div className="cancel-account-content">
        <p>
          Warning: Canceling your account will permanently delete your SalesOptAI Worker and all associated data.
          This action cannot be undone.
        </p>
        
        <button 
          className="danger-button"
          onClick={() => setShowConfirmation(true)}
          disabled={isLoading}
        >
          Cancel Account
        </button>
      </div>

      {showConfirmation && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Are you sure?</h3>
            <p>
              This will permanently delete your account and SalesOptAI Worker. This action cannot be undone.
              {'\n\n'}
              If you're currently in a trial period, your account will be canceled immediately and you will lose access.
              {'\n\n'}
              If you're on a paid subscription, your account will remain active until the end of your current billing period.
            </p>
            
            <div className="modal-buttons">
              <button 
                className="secondary-button"
                onClick={() => setShowConfirmation(false)}
                disabled={isLoading}
              >
                Keep My Account
              </button>
              <button 
                className="danger-button"
                onClick={handleCancelAccount}
                disabled={isLoading}
              >
                {isLoading ? 'Canceling...' : 'Yes, Cancel My Account'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CancelAccount;
