import { useState, useContext } from 'react';
import { Check } from 'react-feather';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../UserContext';
import SubscriptionForm from '../components/SubscriptionForm';
import './Signup.css';

const Signup = () => {
  const [businessName, setBusinessName] = useState('');
  const [businessUrl, setBusinessUrl] = useState('');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [tempToken, setTempToken] = useState('');
  const [paymentError, setPaymentError] = useState(null);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [proceedWithBasicInfo, setProceedWithBasicInfo] = useState(false);

  const navigate = useNavigate();
  const { login } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  const validateUrl = (url) => {
    // Remove any protocol prefix if present
    const cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');
    // Extract the domain part (everything up to the first slash or end of string)
    const domain = cleanUrl.split('/')[0];
    // Check if the domain ends with a valid TLD
    const re = /\.(com|ca|net|org|edu|gov|io|co|ai)$/i;
    return re.test(domain);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    if (!validateUrl(businessUrl)) {
      setStatusMessage('Please enter a valid business URL');
      setStatusType('error');
      return false;
    }

    if (!validateEmail(email)) {
      setStatusMessage('Please enter a valid email address');
      setStatusType('error');
      return false;
    }

    if (businessName.trim() === '') {
      setStatusMessage('Please enter your business name');
      setStatusType('error');
      return false;
    }

    return true;
  };

  const updateStatus = (type, message, showLoadingSpinner = true) => {
    setStatusType(type);
    setStatusMessage(message);
    setShowSpinner(showLoadingSpinner);
  };

  const handleInitialSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    updateStatus('info', 'Checking website accessibility...');

    try {
      // First check if website is accessible
      const accessResponse = await fetch(`${apiUrl}/api/signup/check-website`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: businessUrl
        })
      });

      if (!accessResponse.ok) {
        const data = await accessResponse.json();
        updateStatus('error', data.detail || 'Website is not accessible', false);
        setLoading(false);
        return;
      }

      // Then check if website is scrapable
      updateStatus('info', 'Checking website content...');
      const scrapingResponse = await fetch(`${apiUrl}/api/signup/check-website-scraping`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: businessUrl
        })
      });

      const scrapingData = await scrapingResponse.json();
      if (!scrapingData.scrapable) {
        updateStatus('info', scrapingData.message, false);
        setProceedWithBasicInfo(true);
        // Don't return here, allow user to proceed with basic info
      }

      updateStatus('info', 'Sending verification code to your email...');

      const response = await fetch(`${apiUrl}/api/signup/initiate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          business_url: businessUrl,
          email,
          business_name: businessName
        })
      });

      const data = await response.json();

      if (response.ok && data.success) {
        if (data.requires_verification) {
          setShowVerification(true);
          updateStatus('success', 'Please check your email for the verification code', false);
        } else {
          updateStatus('success', data.message, false);
          setBusinessName('');
          setBusinessUrl('');
          setEmail('');
        }
      } else {
        const errorMessage = typeof data.detail === 'object' ? JSON.stringify(data.detail) : data.detail || data.message || 'An error occurred. Please try again.';
        updateStatus('error', errorMessage, false);
      }
    } catch (error) {
      console.error('Error:', error);
      updateStatus('error', 'An unexpected error occurred. Please try again later.', false);
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    if (resendDisabled) return;
    
    setLoading(true);
    updateStatus('info', 'Resending verification code...');
    setResendDisabled(true);

    try {
      const response = await fetch(`${apiUrl}/api/signup/resend-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok && data.success) {
        updateStatus('success', 'New verification code sent to your email', false);
        // Enable resend button after 1 minute
        setTimeout(() => setResendDisabled(false), 60000);
      } else {
        const errorMessage = typeof data.detail === 'object' ? JSON.stringify(data.detail) : data.detail || data.message || 'Failed to resend code';
        updateStatus('error', errorMessage, false);
        setResendDisabled(false);
      }
    } catch (error) {
      console.error('Error:', error);
      updateStatus('error', 'An unexpected error occurred. Please try again later.', false);
      setResendDisabled(false);
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    
    if (!verificationCode) {
      updateStatus('error', 'Please enter the verification code', false);
      return;
    }

    setLoading(true);
    updateStatus('info', 'Verifying code...');

    try {
      const response = await fetch(`${apiUrl}/api/signup/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          code: verificationCode
        })
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setShowVerification(false);
        setShowPayment(true);
        setTempToken(data.temp_token);
        updateStatus('success', 'Email verified! Please set up your payment method to start your free trial.', false);
      } else {
        const errorMessage = typeof data.detail === 'object' ? JSON.stringify(data.detail) : data.detail || data.message || 'An error occurred. Please try again.';
        updateStatus('error', errorMessage, false);
      }
    } catch (error) {
      console.error('Error:', error);
      updateStatus('error', 'An unexpected error occurred. Please try again later.', false);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentSuccess = async (data) => {
    try {
      setCreatingAccount(true);
      updateStatus('info', 'Setting up your payment method...');

      // More detailed sequence of status messages with longer intervals
      setTimeout(() => updateStatus('info', 'Analyzing your website structure...'), 3000);
      setTimeout(() => updateStatus('info', 'Scanning website content...'), 15000);
      setTimeout(() => updateStatus('info', 'Extracting business information...'), 35000);
      setTimeout(() => updateStatus('info', 'Building AI knowledge base...'), 60000);
      setTimeout(() => updateStatus('info', 'Training AI assistant with your business data...'), 90000);
      setTimeout(() => updateStatus('info', 'Configuring phone system...'), 110000);
      setTimeout(() => updateStatus('info', 'Finalizing your workspace...'), 120000);

      // Complete signup
      const completeResponse = await fetch(`${apiUrl}/api/signup/complete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: tempToken,
          customer_id: data.customer_id,
          subscription_id: data.subscriptionId,
          payment_method_id: data.payment_method_id
        })
      });

      if (!completeResponse.ok) {
        const completeData = await completeResponse.json();
        throw new Error(completeData.detail || 'Failed to complete signup');
      }

      const completeData = await completeResponse.json();
      localStorage.setItem('access_token', completeData.access_token);
      login(completeData.userId);
      
      updateStatus('success', 'Setup complete! Redirecting to your dashboard...', false);
      
      // Add a small delay before redirecting
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } catch (error) {
      updateStatus('error', error.message, false);
    } finally {
      setCreatingAccount(false);
    }
  };

  const handlePaymentError = (error) => {
    setPaymentError(error);
  };

  const features = [
    'AI Receptionists',
    'Chatbots',
    'Email Responders',
    'SMS Responders',
    'Social Media DM Responders',
    'Outbound Marketing Campaigns'
  ];

  return (
    <div className="signup-container">
      <div className="content-column">
        <h2 className="tagline">
          AUTOMATE YOUR SALES, CUSTOMER SUPPORT, AND ADMIN TASKS - 24/7
        </h2>
        
        <div className="hero-title">
          <span className="highlight-ai-workforce">AI Workforce</span> for<br />
          Small to Medium<br />
          Sized Businesses
        </div>

        <ul className="feature-list">
          {features.map((feature, index) => (
            <li key={index} className="animate-slide-in" style={{ animationDelay: `${index * 0.1}s` }}>
              <Check /> {feature}
            </li>
          ))}
        </ul>
      </div>

      <div className="form-column">
        <div className="form-wrapper">
          {statusMessage && (
            <div 
              className={`status-message ${statusType}-message`}
              style={{
                background: 'rgba(0, 0, 0, 0.5)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                padding: '12px 16px',
                borderRadius: '8px',
                marginBottom: '1rem'
              }}
            >
              {((loading || creatingAccount) && showSpinner) && (
                <div 
                  className="loading-spinner"
                  style={{
                    width: '20px',
                    height: '20px',
                    border: '2px solid #ffffff',
                    borderTop: '2px solid transparent',
                    borderRadius: '50%',
                    animation: 'spin 1s linear infinite',
                    flexShrink: 0
                  }}
                />
              )}
              <p style={{
                margin: 0,
                color: 'white',
                fontWeight: 'bold',
                fontSize: '0.95rem',
                lineHeight: 1.4,
                flex: 1,
                textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
              }}>
                {statusMessage}
              </p>
            </div>
          )}

          {!showVerification && !showPayment ? (
            <>
              <div className="form-logo-wrapper">
                <img src="/images/3.png" alt="SalesOptAI" className="form-logo" />
              </div>
              <form onSubmit={handleInitialSubmit} className="assistant-form">
                <input
                  type="text"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  placeholder="Business Name"
                  required
                  disabled={loading}
                />
                
                <input
                  type="text"
                  value={businessUrl}
                  onChange={(e) => setBusinessUrl(e.target.value)}
                  placeholder="Business URL"
                  required
                  disabled={loading}
                />

                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                  required
                  disabled={loading}
                />
                
                <button type="submit" className="btn" disabled={loading}>
                  {loading ? (
                    <>
                      <span className="loading-spinner"></span>
                      Processing...
                    </>
                  ) : (
                    'Start 7-Day Free Trial'
                  )}
                </button>
              </form>
              <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <p>
                  Already have an account? <Link to="/login" style={{ color: 'var(--accent)' }}>Log In</Link>
                </p>
              </div>
            </>
          ) : showVerification ? (
            <>
              <div className="form-logo-wrapper">
                <img src="/images/3.png" alt="SalesOptAI" className="form-logo" />
              </div>
              <form onSubmit={handleVerificationSubmit} className="assistant-form">
                <p className="verification-message">
                  Please enter the verification code sent to your email
                </p>
                <div style={{ position: 'relative', width: '100%', marginBottom: '1rem' }}>
                  <input
                    type="text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    placeholder="Verification Code"
                    required
                    disabled={loading}
                    maxLength={6}
                    pattern="\d{6}"
                    style={{ paddingRight: '100px' }}
                  />
                  <button
                    type="button"
                    onClick={handleResendCode}
                    disabled={loading || resendDisabled}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      background: 'none',
                      border: 'none',
                      color: 'var(--accent)',
                      cursor: resendDisabled ? 'not-allowed' : 'pointer',
                      opacity: resendDisabled ? 0.5 : 1,
                      padding: '5px',
                      fontSize: '0.9em'
                    }}
                  >
                    Resend Code
                  </button>
                </div>
                
                <button type="submit" className="btn" disabled={loading}>
                  {loading ? (
                    <>
                      <span className="loading-spinner"></span>
                      Verifying...
                    </>
                  ) : (
                    'Verify Email'
                  )}
                </button>
              </form>
            </>
          ) : (
            <div style={{ width: '100%' }}>
              <div className="form-logo-wrapper">
                <img src="/images/3.png" alt="SalesOptAI" className="form-logo" />
              </div>
              {paymentError && (
                <div style={{
                  color: '#e53e3e',
                  background: 'rgba(229, 62, 62, 0.1)',
                  padding: '0.75rem',
                  borderRadius: '8px',
                  margin: '1rem 0',
                  textAlign: 'center'
                }}>
                  {paymentError}
                </div>
              )}
              <div style={{ width: '100%', display: 'block' }}>
                <h3 style={{ textAlign: 'center', marginBottom: '1.5rem', color: 'white' }}>
                  Set Up Your Free Trial
                </h3>
                {!creatingAccount && (
                  <SubscriptionForm 
                    email={email}
                    onSuccess={handlePaymentSuccess}
                    onError={handlePaymentError}
                    useBasicInfo={proceedWithBasicInfo}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signup;
