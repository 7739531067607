import { FileText, Trash2, Eye, Save } from 'react-feather';
import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../config/api';

const DocumentsSection = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentContent, setDocumentContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);

  const getFileType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return extension;
  };

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/documents`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setDocuments(data);
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchDocuments();
    } else {
      setLoading(false);
    }
  }, [userId]);

  const viewDocument = async (documentId, documentName) => {
    try {
      const fileType = getFileType(documentName);
      setSelectedDocumentType(fileType);

      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/documents/${documentId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        if (fileType === 'txt') {
          // Handle text files normally
          const data = await response.json();
          setDocumentContent(data.content);
          setSelectedDocument(documentId);
          setIsEditing(false);
          setSaveStatus('');
        } else {
          // For non-text files, get blob and create object URL
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          
          // Open in new window/tab
          window.open(url, '_blank');
          
          // Clean up
          window.URL.revokeObjectURL(url);
          setSelectedDocument(null);
          setDocumentContent('');
          setIsEditing(false);
        }
      }
    } catch (error) {
      console.error('Error fetching document content:', error);
    }
  };

  const saveDocument = async () => {
    if (!selectedDocument || selectedDocumentType !== 'txt') return;

    try {
      setSaveStatus('Saving...');
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/documents/${selectedDocument}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ content: documentContent })
      });

      if (response.ok) {
        setSaveStatus('Saved successfully!');
        // Close the document view after successful save
        setTimeout(() => {
          setSelectedDocument(null);
          setDocumentContent('');
          setIsEditing(false);
          setSaveStatus('');
          setSelectedDocumentType(null);
        }, 1000);
      } else {
        setSaveStatus('Error saving document');
      }
    } catch (error) {
      console.error('Error saving document:', error);
      setSaveStatus('Error saving document');
    }
  };

  const deleteDocument = async (documentId) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/documents/${documentId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        await fetchDocuments();
        if (selectedDocument === documentId) {
          setSelectedDocument(null);
          setDocumentContent('');
          setIsEditing(false);
          setSaveStatus('');
          setSelectedDocumentType(null);
        }
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
    setUploadStatus('');
  };

  const handleUpload = async () => {
    if (!selectedFiles.length) {
      setUploadStatus('Please select files to upload');
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    try {
      setUploadStatus('Uploading...');
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/upload-documents`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData,
      });

      if (response.ok) {
        await response.json();
        setUploadStatus('Files uploaded successfully');
        setSelectedFiles([]);
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
          fileInput.value = '';
        }
        await fetchDocuments();
      } else {
        const errorData = await response.text();
        setUploadStatus(`Upload failed: ${errorData}`);
      }
    } catch (error) {
      setUploadStatus('Error uploading files. Please try again.');
      console.error('Error uploading files:', error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <section className="account-section">
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          Loading documents...
        </div>
      </section>
    );
  }

  return (
    <section className="account-section">
      <h2 className="section-header">
        <FileText style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
        Knowledge Documents
      </h2>
      <div className="documents-container">
        <p className="section-description">Access and manage your knowledge documents here</p>
        <div className="upload-container">
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="file-input"
          />
          {uploadStatus && (
            <p className={`upload-status ${uploadStatus.includes('success') ? 'success' : 'error'}`}>
              {uploadStatus}
            </p>
          )}
          <button
            onClick={handleUpload}
            className="upload-button"
          >
            Upload Documents
          </button>
        </div>

        <div className="document-list">
          {documents.length === 0 ? (
            <p className="no-documents">No documents uploaded yet.</p>
          ) : (
            documents.map((doc) => (
              <div key={doc.id} className="document-item">
                <div className="document-info">
                  <span className="document-name">{doc.document_name}</span>
                  <span className="document-date">{formatDate(doc.upload_date)}</span>
                </div>
                <div className="document-actions">
                  <button
                    onClick={() => viewDocument(doc.id, doc.document_name)}
                    className="icon-button"
                    title="View Document"
                  >
                    <Eye size={16} />
                  </button>
                  <button
                    onClick={() => deleteDocument(doc.id)}
                    className="icon-button"
                    title="Delete Document"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        {selectedDocument && selectedDocumentType === 'txt' && (
          <div className="document-content-container">
            <div className="document-actions-bar">
              <button
                onClick={() => setIsEditing(!isEditing)}
                className="edit-button"
              >
                {isEditing ? 'Cancel Edit' : 'Edit'}
              </button>
              {isEditing && (
                <button
                  onClick={saveDocument}
                  className="save-button"
                  title="Save Document"
                >
                  <Save size={16} /> Save
                </button>
              )}
              {saveStatus && (
                <span className={`save-status ${saveStatus.includes('Error') ? 'error' : 'success'}`}>
                  {saveStatus}
                </span>
              )}
            </div>
            {isEditing ? (
              <textarea
                className="document-editor"
                value={documentContent}
                onChange={(e) => setDocumentContent(e.target.value)}
              />
            ) : (
              <div className="document-content">
                {documentContent}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default DocumentsSection;
