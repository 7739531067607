import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../config/api';

const Announcements = ({ phoneNumber }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [announcement, setAnnouncement] = useState('');

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/system-settings/welcome-announcement`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch announcement');
        }

        const data = await response.json();
        if (data.template) {
          // Replace the phone number placeholder with the actual phone number
          const formattedAnnouncement = data.template.replace(/\[Insert AI Phone Number Here\]/g, phoneNumber || 'Loading...');
          setAnnouncement(formattedAnnouncement);
        } else {
          console.error('No template found in response:', data);
          setAnnouncement(`Your AI assistant is ready at: ${phoneNumber}`);
        }
      } catch (error) {
        console.error('Error fetching welcome announcement:', error);
        // Fallback to a simple announcement if template fetch fails
        setAnnouncement(`Your AI assistant is ready at: ${phoneNumber}`);
      }
    };

    if (phoneNumber) {
      fetchAnnouncement();
    }
  }, [phoneNumber]);

  if (!isVisible || !announcement) return null;

  return (
    <div style={{
      background: '#2563eb',
      borderRadius: '12px',
      padding: '1.5rem',
      marginBottom: '2rem',
      position: 'relative'
    }}>
      <button 
        onClick={() => setIsVisible(false)}
        style={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          background: 'none',
          border: 'none',
          color: 'white',
          fontSize: '1.2rem',
          cursor: 'pointer',
          padding: '0.2rem 0.5rem'
        }}
      >
        ×
      </button>
      <div 
        style={{ 
          color: 'white',
          whiteSpace: 'pre-wrap'  // Preserve line breaks from the template
        }}
        dangerouslySetInnerHTML={{ __html: announcement.split('*').map((part, i) => 
          i % 2 === 0 ? part : `<strong>${part}</strong>`
        ).join('') }}
      />
    </div>
  );
};

export default Announcements;
