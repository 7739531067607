import React, { useState, useEffect } from 'react';
import PageContainer from '../components/PageContainer';
import { API_BASE_URL } from '../config/api';
import { Settings, Terminal, Users, MessageCircle } from 'react-feather';
import '../styles/account.css';

const SystemSettings = () => {
  const [template, setTemplate] = useState('');
  const [originalTemplate, setOriginalTemplate] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [testRunning, setTestRunning] = useState(false);
  const [testOutput, setTestOutput] = useState('');
  const [testError, setTestError] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [usersLoading, setUsersLoading] = useState(true);
  const [usersError, setUsersError] = useState('');

  // Welcome announcement state
  const [welcomeTemplate, setWelcomeTemplate] = useState('');
  const [originalWelcomeTemplate, setOriginalWelcomeTemplate] = useState('');
  const [welcomeError, setWelcomeError] = useState('');
  const [welcomeSuccessMessage, setWelcomeSuccessMessage] = useState('');
  const [savingWelcome, setSavingWelcome] = useState(false);

  useEffect(() => {
    fetchTemplate();
    fetchWelcomeTemplate();
    fetchUsers();
  }, []);

  const fetchTemplate = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/system-settings/prompt-template`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch template');
      }

      const data = await response.json();
      setTemplate(data.template);
      setOriginalTemplate(data.template);
      setLoading(false);
    } catch (err) {
      setError('Failed to load template');
      setLoading(false);
    }
  };

  const fetchWelcomeTemplate = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/system-settings/welcome-announcement`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch welcome template');
      }

      const data = await response.json();
      setWelcomeTemplate(data.template);
      setOriginalWelcomeTemplate(data.template);
    } catch (err) {
      setWelcomeError('Failed to load welcome template');
    }
  };

  const fetchUsers = async () => {
    setUsersLoading(true);
    setUsersError('');
    try {
      console.log('Fetching users from:', `${API_BASE_URL}/api/system-settings/users`);
      const response = await fetch(`${API_BASE_URL}/api/system-settings/users`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to fetch users:', errorData);
        throw new Error(errorData.detail || 'Failed to fetch users');
      }

      const data = await response.json();
      console.log('Users data:', data);
      setUsers(data);
    } catch (err) {
      console.error('Error fetching users:', err);
      setUsersError(err.message);
    } finally {
      setUsersLoading(false);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    setError('');
    setSuccessMessage('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/system-settings/prompt-template`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ template })
      });

      if (!response.ok) {
        throw new Error('Failed to update template');
      }

      setOriginalTemplate(template);
      setSuccessMessage('Template updated successfully');
    } catch (err) {
      setError('Failed to save changes');
    } finally {
      setSaving(false);
    }
  };

  const handleSaveWelcome = async () => {
    setSavingWelcome(true);
    setWelcomeError('');
    setWelcomeSuccessMessage('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/system-settings/welcome-announcement`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ template: welcomeTemplate })
      });

      if (!response.ok) {
        throw new Error('Failed to update welcome template');
      }

      setOriginalWelcomeTemplate(welcomeTemplate);
      setWelcomeSuccessMessage('Welcome announcement updated successfully');
    } catch (err) {
      setWelcomeError('Failed to save changes');
    } finally {
      setSavingWelcome(false);
    }
  };

  const handleCancel = () => {
    setTemplate(originalTemplate);
    setError('');
    setSuccessMessage('');
  };

  const handleCancelWelcome = () => {
    setWelcomeTemplate(originalWelcomeTemplate);
    setWelcomeError('');
    setWelcomeSuccessMessage('');
  };

  const runOpenAITest = async () => {
    setTestRunning(true);
    setTestOutput('');
    setTestError('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/system-settings/run-openai-test`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();
      
      if (response.ok && data.success) {
        setTestOutput(data.output);
      } else {
        setTestError(data.output || 'Failed to run OpenAI test');
      }
    } catch (err) {
      setTestError('Failed to run OpenAI test');
    } finally {
      setTestRunning(false);
    }
  };

  const removeUser = async () => {
    if (!selectedUser) return;

    setRemoving(true);
    setError('');
    setSuccessMessage('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/system-settings/remove-user`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: selectedUser.id })
      });

      const data = await response.json();
      
      if (response.ok && data.success) {
        setSuccessMessage(data.message);
        fetchUsers(); // Refresh user list
      } else {
        setError(data.detail || 'Failed to remove user');
      }
    } catch (err) {
      setError('Failed to remove user');
    } finally {
      setRemoving(false);
      setShowConfirmation(false);
      setSelectedUser(null);
    }
  };

  const hasChanges = template !== originalTemplate;
  const hasWelcomeChanges = welcomeTemplate !== originalWelcomeTemplate;

  if (loading) {
    return (
      <PageContainer
        loading={loading}
        loadingMessage="Loading system settings..."
        title="System Settings"
        subtitle="Configure global system parameters"
      >
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          Loading...
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      title="System Settings"
      subtitle="Configure global system parameters"
    >
      <section className="account-section">
        <h2 className="section-header">
          <Settings style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
          Assistant Prompt Template
        </h2>
        {error && (
          <div className="save-message error">
            {error}
          </div>
        )}
        {successMessage && (
          <div className="save-message success">
            {successMessage}
          </div>
        )}
        <div className="grid-container">
          <div className="input-group full-width">
            <textarea
              className="prompt-textarea"
              value={template}
              onChange={(e) => setTemplate(e.target.value)}
              placeholder="Enter your assistant prompt template"
            />
          </div>
          <div className="input-group full-width">
            <div style={{ 
              color: 'rgba(255, 255, 255, 0.7)', 
              padding: '0.75rem',
              background: 'rgba(255, 255, 255, 0.08)',
              borderRadius: 'var(--border-radius-md)',
              border: '1px solid rgba(255, 255, 255, 0.15)'
            }}>
              <strong style={{ color: 'white' }}>Available Variables:</strong>{' '}
              {'{{'}&nbsp;assistant_name{'}}'}, {'{{'}&nbsp;business_name{'}}'}, {'{{'}&nbsp;business_type{'}}'}, {'{{'}&nbsp;location{'}}'}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
          <button
            className="primary-button"
            onClick={handleSave}
            disabled={!hasChanges || saving}
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </button>
          <button
            className="primary-button"
            onClick={handleCancel}
            disabled={!hasChanges || saving}
            style={{ background: 'rgba(255, 255, 255, 0.1)' }}
          >
            Cancel
          </button>
        </div>
      </section>

      <section className="account-section" style={{ marginTop: '2rem' }}>
        <h2 className="section-header">
          <MessageCircle style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
          Welcome Announcement Template
        </h2>
        {welcomeError && (
          <div className="save-message error">
            {welcomeError}
          </div>
        )}
        {welcomeSuccessMessage && (
          <div className="save-message success">
            {welcomeSuccessMessage}
          </div>
        )}
        <div className="grid-container">
          <div className="input-group full-width">
            <textarea
              className="prompt-textarea"
              value={welcomeTemplate}
              onChange={(e) => setWelcomeTemplate(e.target.value)}
              placeholder="Enter your welcome announcement template"
              style={{ 
                minHeight: '300px',
                fontFamily: 'system-ui, -apple-system, sans-serif' // Use system font to ensure emoji support
              }}
            />
          </div>
          <div className="input-group full-width">
            <div style={{ 
              color: 'rgba(255, 255, 255, 0.7)', 
              padding: '0.75rem',
              background: 'rgba(255, 255, 255, 0.08)',
              borderRadius: 'var(--border-radius-md)',
              border: '1px solid rgba(255, 255, 255, 0.15)',
              fontFamily: 'system-ui, -apple-system, sans-serif' // Use system font to ensure emoji support
            }}>
              <strong style={{ color: 'white' }}>Formatting Tips:</strong><br />
              • Use *asterisks* around text to make it bold<br />
              • The phone number placeholder [Insert AI Phone Number Here] will be automatically replaced<br />
              • Emojis are supported: 📞 🚀
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
          <button
            className="primary-button"
            onClick={handleSaveWelcome}
            disabled={!hasWelcomeChanges || savingWelcome}
          >
            {savingWelcome ? 'Saving...' : 'Save Changes'}
          </button>
          <button
            className="primary-button"
            onClick={handleCancelWelcome}
            disabled={!hasWelcomeChanges || savingWelcome}
            style={{ background: 'rgba(255, 255, 255, 0.1)' }}
          >
            Cancel
          </button>
        </div>
      </section>

      <section className="account-section" style={{ marginTop: '2rem' }}>
        <h2 className="section-header">
          <Terminal style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
          System Tests
        </h2>
        {testError && (
          <div className="save-message error">
            {testError}
          </div>
        )}
        <div className="grid-container">
          <div className="input-group full-width">
            <button
              className="primary-button"
              onClick={runOpenAITest}
              disabled={testRunning}
              style={{ marginBottom: '1rem' }}
            >
              {testRunning ? 'Running Test...' : 'Run OpenAI Test'}
            </button>
            {testOutput && (
              <pre style={{ 
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                padding: '1rem',
                background: 'rgba(255, 255, 255, 0.08)',
                borderRadius: 'var(--border-radius-md)',
                border: '1px solid rgba(255, 255, 255, 0.15)',
                color: 'white',
                fontFamily: 'monospace',
                fontSize: '0.9rem',
                maxHeight: '400px',
                overflowY: 'auto'
              }}>
                {testOutput}
              </pre>
            )}
          </div>
        </div>
      </section>

      <section className="account-section" style={{ marginTop: '2rem' }}>
        <h2 className="section-header">
          <Users style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
          User Management
        </h2>
        <div className="grid-container">
          <div className="input-group full-width">
            {usersError && (
              <div style={{ 
                color: '#ff4444', 
                padding: '0.75rem',
                background: 'rgba(255, 68, 68, 0.1)',
                borderRadius: 'var(--border-radius-md)',
                border: '1px solid rgba(255, 68, 68, 0.3)',
                marginBottom: '1rem'
              }}>
                <strong>Error:</strong> {usersError}
              </div>
            )}
            {usersLoading ? (
              <div style={{ textAlign: 'center', padding: '2rem' }}>
                Loading users...
              </div>
            ) : users.length > 0 ? (
              <>
                <div style={{ 
                  color: '#ff4444', 
                  padding: '0.75rem',
                  background: 'rgba(255, 68, 68, 0.1)',
                  borderRadius: 'var(--border-radius-md)',
                  border: '1px solid rgba(255, 68, 68, 0.3)',
                  marginBottom: '1rem'
                }}>
                  <strong>Warning:</strong> Removing a user will permanently delete their account and all associated data.
                </div>
                <div style={{ 
                  background: 'rgba(255, 255, 255, 0.08)',
                  borderRadius: 'var(--border-radius-md)',
                  border: '1px solid rgba(255, 255, 255, 0.15)',
                  marginBottom: '1rem'
                }}>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.15)' }}>
                        <th style={{ padding: '0.75rem', textAlign: 'left' }}>ID</th>
                        <th style={{ padding: '0.75rem', textAlign: 'left' }}>Email</th>
                        <th style={{ padding: '0.75rem', textAlign: 'left' }}>Admin</th>
                        <th style={{ padding: '0.75rem', textAlign: 'right' }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map(user => (
                        <tr key={user.id} style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                          <td style={{ padding: '0.75rem' }}>{user.id}</td>
                          <td style={{ padding: '0.75rem' }}>{user.email}</td>
                          <td style={{ padding: '0.75rem' }}>{user.is_admin ? 'Yes' : 'No'}</td>
                          <td style={{ padding: '0.75rem', textAlign: 'right' }}>
                            {user.id !== 23 && (
                              <button
                                className="primary-button"
                                onClick={() => {
                                  setSelectedUser(user);
                                  setShowConfirmation(true);
                                }}
                                style={{ background: '#ff4444', padding: '0.5rem 1rem' }}
                              >
                                Remove
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div style={{ textAlign: 'center', padding: '2rem', color: 'rgba(255, 255, 255, 0.7)' }}>
                No users found
              </div>
            )}
            {showConfirmation && selectedUser && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div style={{ 
                  color: 'white',
                  padding: '0.75rem',
                  background: 'rgba(255, 68, 68, 0.2)',
                  borderRadius: 'var(--border-radius-md)',
                  border: '1px solid rgba(255, 68, 68, 0.4)'
                }}>
                  Are you absolutely sure you want to remove user {selectedUser.email} (ID: {selectedUser.id})? This action cannot be undone.
                </div>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <button
                    className="primary-button"
                    onClick={removeUser}
                    disabled={removing}
                    style={{ background: '#ff4444' }}
                  >
                    {removing ? 'Removing User...' : 'Yes, Remove User'}
                  </button>
                  <button
                    className="primary-button"
                    onClick={() => {
                      setShowConfirmation(false);
                      setSelectedUser(null);
                    }}
                    disabled={removing}
                    style={{ background: 'rgba(255, 255, 255, 0.1)' }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </PageContainer>
  );
};

export default SystemSettings;
