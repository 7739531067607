import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext';
import PageContainer from '../components/PageContainer';
import { fetchDashboardData } from './dashboard/DashboardUtils';
import StatsGrid from './dashboard/StatsGrid';
import AIAnalysis from './dashboard/AIAnalysis';
import RecentActivity from './dashboard/RecentActivity';
import Announcements from './dashboard/Announcements';

const Dashboard = () => {
  const { userId } = useContext(UserContext);
  const [userData, setUserData] = useState({
    business_name: '',
    phone_number: '',
    credit_balance: 0,
    subscription_status: 'trial',
    trial_end_date: null,
    recent_usage: [],
    usage_stats: {
      total_calls: 0,
      total_chats: 0,
      total_duration: 0,
      total_customers: 0
    },
    analysis: {
      daily: {
        total_interactions: 0,
        avg_duration: 0,
        sentiment_score: 0,
        ai_leads: 0,
        ai_closed_deals: 0,
        ai_prospects: 0
      },
      weekly: {
        total_interactions: 0,
        avg_duration: 0,
        sentiment_score: 0,
        ai_leads: 0,
        ai_closed_deals: 0,
        ai_prospects: 0
      },
      monthly: {
        total_interactions: 0,
        avg_duration: 0,
        sentiment_score: 0,
        ai_leads: 0,
        ai_closed_deals: 0,
        ai_prospects: 0
      }
    }
  });
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        setPageLoading(true);
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          console.error('No access token found');
          return;
        }

        const data = await fetchDashboardData(accessToken, userId);
        setUserData(data);
      } catch (error) {
        console.error('Error loading dashboard data:', error);
      } finally {
        setPageLoading(false);
      }
    };

    if (userId) {
      loadData();
    }
  }, [userId]);

  return (
    <PageContainer
      loading={pageLoading}
      loadingMessage="Loading dashboard..."
      title={`Welcome: ${userData.business_name}`}
      subtitle="Homebase for Your AI Workforce"
    >
      <Announcements phoneNumber={userData.phone_number} />
      <StatsGrid userData={userData} />
      <AIAnalysis analysis={userData.analysis} />
      <RecentActivity recentUsage={userData.recent_usage} />
    </PageContainer>
  );
};

export default Dashboard;
