import React, { useState, useEffect } from 'react';
import { CreditCard } from 'react-feather';
import PaymentForm from '../../components/PaymentForm';
import { updateAutoRecharge } from './BillingUtils';
import { API_BASE_URL } from '../../config/api';
import './billing.css';

const CreditBalance = ({ 
  creditBalance = 0,
  loading = false,
  onPurchaseSuccess,
  onError,
  paymentMethods = []
}) => {
  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [minBalance, setMinBalance] = useState('');
  const [rechargeAmount, setRechargeAmount] = useState('');
  const [autoRechargeEnabled, setAutoRechargeEnabled] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('new');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      const defaultMethod = paymentMethods.find(pm => pm.is_default);
      setSelectedPaymentMethod(defaultMethod ? defaultMethod.id : paymentMethods[0].id);
    } else {
      setSelectedPaymentMethod('new');
    }
  }, [paymentMethods]);

  // Fetch initial auto recharge settings
  useEffect(() => {
    const fetchAutoRechargeSettings = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${API_BASE_URL}/api/stripe/balance/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        
        if (data.auto_recharge_minimum !== null && data.auto_recharge_amount !== null) {
          setAutoRechargeEnabled(true);
          setMinBalance(data.auto_recharge_minimum.toString());
          setRechargeAmount(data.auto_recharge_amount.toString());
        }
      } catch (error) {
        console.error('Error fetching auto recharge settings:', error);
      }
    };

    fetchAutoRechargeSettings();
  }, []);

  const handlePurchaseCredit = async () => {
    if (!purchaseAmount || parseFloat(purchaseAmount) < 20) {
      onError('Minimum purchase amount is $20');
      return;
    }

    if (selectedPaymentMethod === 'new') {
      setShowPaymentForm(true);
    } else {
      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${API_BASE_URL}/api/stripe/purchase-credit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            amount: parseFloat(purchaseAmount),
            payment_method_id: selectedPaymentMethod,
            user_id: parseInt(userId)
          }),
        });

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.detail || 'Failed to purchase credit');
        }

        const data = await response.json();
        setPurchaseAmount('');
        if (onPurchaseSuccess) {
          onPurchaseSuccess(data);
        }
      } catch (error) {
        onError(error.message || 'Failed to process payment');
      }
    }
  };

  const handlePaymentSuccess = (data) => {
    setPurchaseAmount('');
    setShowPaymentForm(false);
    if (onPurchaseSuccess) {
      onPurchaseSuccess(data);
    }
  };

  const handlePaymentError = (error) => {
    if (error.includes('Failed to') || error.includes('Unable to process payment')) {
      setShowPaymentForm(false);
    }
    onError(error);
  };

  const handleSaveAutoRecharge = async () => {
    try {
      setIsLoading(true);
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const defaultPaymentMethod = paymentMethods.find(pm => pm.is_default);
      
      if (!defaultPaymentMethod) {
        onError('Please set a default payment method first');
        return;
      }

      const requestBody = {
        minimum_balance: parseFloat(minBalance),
        recharge_amount: parseFloat(rechargeAmount),
        user_id: parseInt(userId),
        payment_method_id: defaultPaymentMethod.id,
      };

      const response = await updateAutoRecharge(userId, token, requestBody);
      if (response.status === 'success') {
        alert('Auto-recharge settings updated successfully!');
      } else {
        throw new Error(response.message || 'Failed to update auto-recharge settings');
      }
    } catch (err) {
      onError('Failed to update auto-recharge settings');
      console.error('Error updating auto-recharge settings:', err);
      setAutoRechargeEnabled(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleAutoRecharge = async () => {
    try {
      setIsLoading(true);
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      
      if (!autoRechargeEnabled) {
        setAutoRechargeEnabled(true);
      } else {
        const requestBody = {
          minimum_balance: null,
          recharge_amount: null,
          user_id: parseInt(userId),
          payment_method_id: null,
        };
        const response = await updateAutoRecharge(userId, token, requestBody);
        if (response.status === 'success') {
          setAutoRechargeEnabled(false);
          setMinBalance('');
          setRechargeAmount('');
          alert('Auto-recharge disabled successfully!');
        } else {
          throw new Error(response.message || 'Failed to disable auto-recharge');
        }
      }
    } catch (err) {
      onError('Failed to update auto-recharge settings');
      console.error('Error updating auto-recharge settings:', err);
      // Revert the toggle if there was an error
      setAutoRechargeEnabled(!autoRechargeEnabled);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="credit-balance-card">
      <h2 className="card-title">Usage Credit Balance</h2>
      <p className="balance-amount">${creditBalance.toFixed(2)}</p>
      
      <div className="purchase-section">
        <h3 className="section-title">Purchase Credit</h3>
        <div className="input-group">
          <input 
            type="number" 
            placeholder="Enter Amount ($)" 
            min="20"
            value={purchaseAmount}
            onChange={(e) => setPurchaseAmount(e.target.value)}
            className="amount-input"
          />
        </div>

        {paymentMethods.length > 0 && !showPaymentForm && (
          <div className="payment-method-select">
            <label className="input-label">
              Payment Method
            </label>
            <select
              value={selectedPaymentMethod}
              onChange={(e) => setSelectedPaymentMethod(e.target.value)}
              className="select-input"
            >
              {paymentMethods.map(method => (
                <option key={method.id} value={method.id}>
                  {method.brand.toUpperCase()} •••• {method.last4} {method.is_default ? '(Default)' : ''}
                </option>
              ))}
              <option value="new">Add New Card</option>
            </select>
          </div>
        )}

        {!showPaymentForm ? (
          <button 
            onClick={handlePurchaseCredit}
            disabled={loading || !purchaseAmount}
            className="purchase-button"
          >
            <CreditCard size={16} />
            {loading ? 'Processing...' : selectedPaymentMethod === 'new' ? 'Continue with New Card' : 'Purchase Credit'}
          </button>
        ) : (
          <PaymentForm
            mode="credit"
            amount={purchaseAmount}
            onSuccess={handlePaymentSuccess}
            onError={handlePaymentError}
          />
        )}
      </div>

      <div className="auto-recharge-section">
        <div className="auto-recharge-header">
          <h3 className="section-title">Auto Recharge Settings</h3>
          <button
            onClick={handleToggleAutoRecharge}
            className={`toggle-button ${autoRechargeEnabled ? 'active' : ''}`}
            disabled={isLoading}
          >
            <div className="toggle-slider" />
          </button>
        </div>

        {autoRechargeEnabled && (
          <>
            <div className="auto-recharge-inputs">
              <label className="input-label">
                Recharge when balance falls below:
              </label>
              <input 
                type="number" 
                placeholder="Minimum Balance ($)" 
                min="10"
                value={minBalance}
                onChange={(e) => setMinBalance(e.target.value)}
                className="amount-input"
              />
              <label className="input-label">
                Recharge amount:
              </label>
              <input 
                type="number" 
                placeholder="Amount to Add ($)" 
                min="20"
                value={rechargeAmount}
                onChange={(e) => setRechargeAmount(e.target.value)}
                className="amount-input"
              />
            </div>

            <button 
              onClick={handleSaveAutoRecharge}
              disabled={isLoading || !minBalance || !rechargeAmount}
              className="update-button"
            >
              {isLoading ? 'Saving...' : 'Update Auto-Recharge Settings'}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default CreditBalance;
