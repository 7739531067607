import React from 'react';
import { DollarSign, Phone, MessageCircle, Users, Award } from 'react-feather';
import { getSubscriptionStatusColor, getSubscriptionStatusLabel } from './DashboardUtils';

const StatCard = ({ icon: Icon, title, value, color, subtitle = null }) => (
  <div style={{
    background: 'rgba(255, 255, 255, 0.08)',
    borderRadius: '12px',
    padding: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem'
  }}>
    <div style={{
      background: `rgba(${color}, 0.1)`,
      borderRadius: '50%',
      padding: '0.8rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Icon size={24} color={`rgb(${color})`} />
    </div>
    <div>
      <h3 style={{ color: 'white', margin: '0', fontSize: '1.8rem' }}>{value}</h3>
      <p style={{ color: '#f0f0f0', margin: '0.2rem 0 0 0', opacity: 0.7 }}>{title}</p>
      {subtitle && (
        <p style={{ 
          color: '#f0f0f0', 
          margin: '0.2rem 0 0 0', 
          opacity: 0.5,
          fontSize: '0.8rem' 
        }}>
          {subtitle}
        </p>
      )}
    </div>
  </div>
);

const StatsGrid = ({ userData = {} }) => {
  // Ensure usage_stats exists with default values
  const usage_stats = userData.usage_stats || {
    total_calls: 0,
    total_chats: 0,
    total_customers: 0
  };

  const subscriptionStatus = userData.subscription_status || 'trial';
  const creditBalance = userData.credit_balance || 0;
  const subscriptionColor = getSubscriptionStatusColor(subscriptionStatus);
  const subscriptionLabel = getSubscriptionStatusLabel(subscriptionStatus);
  
  // Safely format trial end date - convert Unix timestamp (seconds) to JS timestamp (milliseconds)
  const trialEndDate = userData.trial_end_date ? 
    new Date(userData.trial_end_date * 1000).toLocaleDateString('en-US', { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric' 
    }) : 'Not available';

  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
      gap: '1.5rem',
      marginBottom: '2rem'
    }}>
      <StatCard
        icon={Award}
        title="Subscription Status"
        value={subscriptionLabel}
        color={subscriptionColor}
        subtitle={subscriptionStatus === 'trial' ? `Trial ends: ${trialEndDate}` : null}
      />
      <StatCard
        icon={DollarSign}
        title="Credit Balance"
        value={`$${creditBalance.toFixed(2)}`}
        color="34, 205, 148"
      />
      <StatCard
        icon={Phone}
        title="Total Calls"
        value={usage_stats.total_calls}
        color="64, 156, 255"
      />
      <StatCard
        icon={MessageCircle}
        title="Total Chats"
        value={usage_stats.total_chats}
        color="255, 159, 64"
      />
      <StatCard
        icon={Users}
        title="Total Customers"
        value={usage_stats.total_customers}
        color="255, 99, 132"
      />
    </div>
  );
};

export default StatsGrid;
