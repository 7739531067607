import React, { useState } from 'react';
import { Activity, Phone, MessageCircle } from 'react-feather';
import { formatDate } from './BillingUtils';

const UsageModal = ({ usage, onClose }) => {
  if (!usage) return null;

  const formatDetails = (details) => {
    if (!details) return null;
    
    return Object.entries(details).map(([key, value]) => {
      // Format key from snake_case to Title Case
      const formattedKey = key.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      
      // Format value based on type
      let formattedValue = value;
      if (key === 'duration') {
        const minutes = Math.floor(value / 60);
        const seconds = value % 60;
        formattedValue = `${minutes}m ${seconds}s`;
      }
      
      return (
        <p key={key} style={{ color: 'white', marginBottom: '0.5rem' }}>
          <strong>{formattedKey}:</strong> {formattedValue}
        </p>
      );
    });
  };

  const getServiceIcon = (type) => {
    switch (type) {
      case 'vapi_call':
        return <Phone size={20} style={{ marginRight: '8px' }} />;
      case 'chat_completion':
        return <MessageCircle size={20} style={{ marginRight: '8px' }} />;
      default:
        return <Activity size={20} style={{ marginRight: '8px' }} />;
    }
  };

  const formatServiceType = (type) => {
    switch (type) {
      case 'vapi_call':
        return 'Voice Call';
      case 'chat_completion':
        return 'Chat Completion';
      default:
        return type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div style={{
        background: '#1a1a1a',
        padding: '2rem',
        borderRadius: '12px',
        width: '90%',
        maxWidth: '500px',
        maxHeight: '90vh',
        overflowY: 'auto',
        position: 'relative'
      }}>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            background: 'none',
            border: 'none',
            color: 'white',
            fontSize: '1.5rem',
            cursor: 'pointer'
          }}
        >
          ×
        </button>
        
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
          {getServiceIcon(usage.service_type)}
          <h2 style={{ color: 'white', margin: 0 }}>{formatServiceType(usage.service_type)}</h2>
        </div>
        
        <div style={{ marginBottom: '1rem' }}>
          <p style={{ color: 'white', marginBottom: '0.5rem' }}>
            <strong>Cost:</strong> ${usage.cost.toFixed(2)}
          </p>
          <p style={{ color: 'white', marginBottom: '0.5rem' }}>
            <strong>Date:</strong> {formatDate(usage.timestamp)}
          </p>

          {usage.details && (
            <div style={{ marginTop: '1rem' }}>
              <strong style={{ color: 'white', display: 'block', marginBottom: '0.5rem' }}>Details:</strong>
              {formatDetails(usage.details)}
            </div>
          )}

          {usage.conversation && (
            <div style={{ marginTop: '1rem', padding: '1rem', background: 'rgba(255, 255, 255, 0.05)', borderRadius: '8px' }}>
              <strong style={{ color: 'white', display: 'block', marginBottom: '0.5rem' }}>Conversation Details:</strong>
              {usage.conversation.customer_name && (
                <p style={{ color: 'white', marginBottom: '0.5rem' }}>
                  <strong>Customer:</strong> {usage.conversation.customer_name}
                </p>
              )}
              {usage.conversation.customer_phone && (
                <p style={{ color: 'white', marginBottom: '0.5rem' }}>
                  <strong>Phone:</strong> {usage.conversation.customer_phone}
                </p>
              )}
              {usage.conversation.duration && (
                <p style={{ color: 'white', marginBottom: '0.5rem' }}>
                  <strong>Duration:</strong> {Math.floor(usage.conversation.duration / 60)}m {usage.conversation.duration % 60}s
                </p>
              )}
              {usage.conversation.summary && (
                <p style={{ color: 'white', marginBottom: '0.5rem' }}>
                  <strong>Summary:</strong> {usage.conversation.summary}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const UsageHistory = ({ usageData = [] }) => {
  const [selectedUsage, setSelectedUsage] = useState(null);

  const getServiceIcon = (type) => {
    switch (type) {
      case 'vapi_call':
        return <Phone size={16} style={{ marginRight: '8px' }} />;
      case 'chat_completion':
        return <MessageCircle size={16} style={{ marginRight: '8px' }} />;
      default:
        return <Activity size={16} style={{ marginRight: '8px' }} />;
    }
  };

  const formatServiceType = (type) => {
    switch (type) {
      case 'vapi_call':
        return 'Voice Call';
      case 'chat_completion':
        return 'Chat Completion';
      default:
        return type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
  };

  return (
    <div style={{
      background: 'rgba(255, 255, 255, 0.08)',
      borderRadius: '12px',
      padding: '1.5rem',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      marginTop: '2rem'
    }}>
      {usageData.length > 0 ? (
        <div>
          {usageData.map((usage) => (
            <div
              key={usage.id}
              onClick={() => setSelectedUsage(usage)}
              style={{
                background: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '8px',
                padding: '1rem',
                marginBottom: '1rem',
                cursor: 'pointer',
                transition: 'background-color 0.2s',
                ':hover': {
                  background: 'rgba(255, 255, 255, 0.1)'
                }
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
                  {getServiceIcon(usage.service_type)}
                  <div>
                    <div style={{ fontSize: '1.1rem', marginBottom: '0.2rem' }}>
                      {formatServiceType(usage.service_type)} - ${usage.cost.toFixed(2)}
                    </div>
                    <div style={{ fontSize: '0.9rem', opacity: 0.7 }}>
                      {formatDate(usage.timestamp)}
                    </div>
                    {usage.conversation?.customer_name && (
                      <div style={{ fontSize: '0.9rem', opacity: 0.7 }}>
                        {usage.conversation.customer_name}
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ color: '#22cd94', fontSize: '0.9rem' }}>
                  View Details
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <Activity style={{ color: 'white', width: '2rem', height: '2rem' }} />
          <div>
            <h3 style={{ margin: 0, fontSize: '1.3rem', color: 'white' }}>Usage History</h3>
            <p style={{ margin: '0.5rem 0 0 0', opacity: 0.9, color: '#f0f0f0' }}>No usage history to display</p>
          </div>
        </div>
      )}

      {selectedUsage && (
        <UsageModal
          usage={selectedUsage}
          onClose={() => setSelectedUsage(null)}
        />
      )}
    </div>
  );
};

export default UsageHistory;
