import { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import { API_BASE_URL } from '../config/api';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await fetch(`${API_BASE_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.detail || 'Failed to login');
      }

      const data = await response.json();
      if (data.success) {
        // Store both userId and access_token
        localStorage.setItem('access_token', data.access_token);
        login(data.userId, data.isAdmin); // Pass isAdmin status to login function
        navigate('/dashboard');
      } else {
        throw new Error(data.message || 'Failed to login');
      }
    } catch (err) {
      setError(err.message || 'Failed to login');
      console.error('Login error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-container login-page">
      <div className="form-column">
        <div className="form-wrapper">
          <div className="form-logo-wrapper">
            <img src="/images/3.png" alt="SalesOptAI" className="form-logo" style={{ height: 'auto', maxHeight: '100px' }} />
          </div>
          
          {error && <div className="error">{error}</div>}
          
          <form className="assistant-form" onSubmit={handleSubmit} style={{ maxWidth: '500px', width: '100%' }}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
              disabled={loading}
              style={{ maxWidth: '500px', width: '100%' }}
            />
            
            <div style={{ position: 'relative', maxWidth: '500px', width: '100%' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
                disabled={loading}
                style={{ paddingRight: '50px', maxWidth: '500px', width: '100%' }}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  fontSize: '12px',
                  color: 'var(--accent)'
                }}
              >
                {showPassword ? 'Hide' : 'Show'}
              </span>
            </div>
            
            <button type="submit" className="btn" disabled={loading} style={{ maxWidth: '500px', width: '100%' }}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
          
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <p>
              Don't have an account? <Link to="/signup" style={{ color: 'var(--accent)' }}>Sign Up</Link>
            </p>
            <p style={{ marginTop: '0.5rem' }}>
              <Link to="/reset-password" style={{ color: 'var(--accent)' }}>Forgot Password?</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
