import React, { createContext, useState, useEffect } from 'react';

// Create a Context for the user
export const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(() => {
    // Retrieve userId from local storage if available
    const storedUserId = localStorage.getItem('userId');
    return storedUserId ? parseInt(storedUserId, 10) : null;
  });

  const [isAdmin, setIsAdmin] = useState(() => {
    // Retrieve isAdmin from local storage if available
    const storedIsAdmin = localStorage.getItem('isAdmin');
    return storedIsAdmin === 'true';
  });

  // Example: Set the user ID and admin status after login
  const login = (id, adminStatus = false) => {
    const parsedId = parseInt(id, 10);
    setUserId(parsedId);
    setIsAdmin(adminStatus);
    localStorage.setItem('userId', parsedId.toString());
    localStorage.setItem('isAdmin', adminStatus.toString());
  };

  const logout = () => {
    setUserId(null);
    setIsAdmin(false);
    localStorage.removeItem('userId');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('access_token');
  };

  // Update local storage whenever userId or isAdmin changes
  useEffect(() => {
    if (userId) {
      localStorage.setItem('userId', userId.toString());
      localStorage.setItem('isAdmin', isAdmin.toString());
    } else {
      localStorage.removeItem('userId');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('access_token');
    }
  }, [userId, isAdmin]);

  return (
    <UserContext.Provider value={{ userId, isAdmin, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
