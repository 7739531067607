import { API_BASE_URL } from '../../config/api';

export const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const fetchCreditBalance = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/balance/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};

export const fetchPaymentMethods = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/payment-methods/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};

export const attachPaymentMethod = async (userId, token, paymentMethodId) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/payment-methods/${userId}/attach`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      payment_method_id: paymentMethodId,
    }),
  });
  return await response.json();
};

export const fetchTransactions = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/transactions/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};

export const fetchUsageHistory = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/usage/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};

export const fetchSubscriptionDetails = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/subscription/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};

export const fetchAvailableProducts = async (token) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/subscription/products`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};

export const setDefaultPaymentMethod = async (userId, token, paymentMethodId) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/payment-methods/${userId}/set-default`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      payment_method_id: paymentMethodId,
    }),
  });
  return await response.json();
};

export const deletePaymentMethod = async (userId, token, paymentMethodId) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/payment-methods/${userId}/${paymentMethodId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};

export const updateAutoRecharge = async (userId, token, settings) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/auto-recharge`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(settings),
  });
  return await response.json();
};

export const addProduct = async (userId, token, productId, priceId) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/subscription/product`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      product_id: productId,
      price_id: priceId,
      user_id: parseInt(userId)
    })
  });
  return await response.json();
};
