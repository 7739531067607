import React from 'react';
import { Package, Plus } from 'react-feather';
import { addProduct } from './BillingUtils';
import './billing.css';

const Subscription = ({
  subscription,
  subscribedProducts = [],
  availableProducts = [],
  totalAmount = 0,
  loading,
  onSubscriptionChange,
  onError
}) => {
  const handleAddProduct = async (productId, priceId) => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      await addProduct(userId, token, productId, priceId);
      await onSubscriptionChange();
    } catch (err) {
      const errorMessage = err.response?.data?.detail || 'Failed to add product';
      onError(errorMessage);
      console.error('Error adding product:', err);
    }
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  const currentInterval = subscribedProducts.length > 0 ? subscribedProducts[0].interval : 'monthly';

  const filteredProducts = availableProducts.filter(product => 
    !subscribedProducts.some(sp => sp.id === product.id) &&
    product.interval === currentInterval
  );

  const otherIntervalProducts = availableProducts.filter(product => 
    !subscribedProducts.some(sp => sp.id === product.id) &&
    product.interval !== currentInterval
  );

  const getDiscountBadge = (discount) => {
    if (!discount) return null;

    const discountText = discount.coupon.percent_off
      ? `${discount.coupon.percent_off}% off`
      : `$${discount.coupon.amount_off / 100} off`;

    return (
      <span className="discount-badge">
        {discountText}
      </span>
    );
  };

  const getDiscountedPrice = (product, discount) => {
    if (!discount) return product.amount;

    if (discount.coupon.percent_off) {
      return product.amount * (1 - discount.coupon.percent_off / 100);
    } else if (discount.coupon.amount_off) {
      return Math.max(0, product.amount - (discount.coupon.amount_off / 100));
    }

    return product.amount;
  };

  return (
    <div className="subscription-card">
      {subscription && (
        <div className="current-subscriptions">
          <h4 className="section-title">Current Subscriptions</h4>
          {subscribedProducts.map(product => (
            <div key={product.id} className="subscription-item">
              <Package className="subscription-icon" />
              <div className="subscription-details">
                <div className="subscription-header">
                  <div>
                    <h3 className="subscription-name">
                      {product.name}
                      {subscription.trial_end && (
                        <span className="trial-badge">
                          Trial ends {formatDate(subscription.trial_end)}
                        </span>
                      )}
                      {subscription.discount && getDiscountBadge(subscription.discount)}
                    </h3>
                  </div>
                  <span className="subscription-price">
                    {subscription.discount && (
                      <span className="original-price">
                        ${product.amount}/{product.interval}
                      </span>
                    )}
                    <span className={subscription.discount ? 'discounted-price' : ''}>
                      ${getDiscountedPrice(product, subscription.discount).toFixed(2)}/{product.interval}
                    </span>
                  </span>
                </div>
                <p className="subscription-description">
                  {product.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="addons-section">
        <div className="addons-header">
          <h4 className="section-title">Available Add-ons</h4>
          <span className="billing-interval-badge">
            Showing {currentInterval}ly billing add-ons
          </span>
        </div>

        {otherIntervalProducts.length > 0 && (
          <div className="interval-notice">
            Note: {otherIntervalProducts.length} additional add-ons are available with {currentInterval === 'month' ? 'yearly' : 'monthly'} billing. 
            To access these, you would need to change your base subscription's billing interval.
          </div>
        )}

        <div className="addons-grid">
          {filteredProducts.map(product => (
            <div key={product.id} className="addon-card">
              <div className="addon-content">
                <h5 className="addon-name">{product.name}</h5>
                <p className="addon-description">
                  {product.description}
                </p>
              </div>
              <div className="addon-footer">
                <span className="addon-price">
                  ${product.price}/{product.interval}
                </span>
                <button
                  onClick={() => handleAddProduct(product.id, product.price_id)}
                  disabled={loading}
                  className="addon-button"
                >
                  <Plus size={16} />
                  Add
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
