import React from 'react';
import { Activity } from 'react-feather';
import { formatDate, formatDuration } from './DashboardUtils';

const RecentActivity = ({ recentUsage = [] }) => {
  return (
    <div style={{
      background: 'rgba(255, 255, 255, 0.08)',
      borderRadius: '12px',
      padding: '1.5rem',
      marginBottom: '2rem'
    }}>
      <h2 style={{
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        marginBottom: '1.5rem'
      }}>
        <Activity size={24} />
        Recent Activity
      </h2>

      {Array.isArray(recentUsage) && recentUsage.length > 0 ? (
        recentUsage.map((usage, index) => {
          // Ensure usage object exists and has required properties
          if (!usage) return null;

          const serviceType = usage.service_type || 'unknown';
          const timestamp = usage.timestamp || new Date().toISOString();
          const cost = usage.cost || 0;
          const customerName = usage.conversation?.customer_name;
          const duration = usage.conversation?.duration;

          return (
            <div
              key={usage.id || index}
              style={{
                background: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '8px',
                padding: '1rem',
                marginBottom: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div style={{ color: 'white' }}>
                <div style={{ fontSize: '1.1rem', marginBottom: '0.2rem' }}>
                  {serviceType === 'vapi_call' ? 'Voice Call' : 'Chat Completion'}
                  {customerName && ` - ${customerName}`}
                </div>
                <div style={{ fontSize: '0.9rem', opacity: 0.7 }}>
                  {formatDate(timestamp)} - ${cost.toFixed(2)}
                </div>
              </div>
              {duration && (
                <div style={{ color: '#22cd94' }}>
                  {formatDuration(duration)}
                </div>
              )}
            </div>
          );
        })
      ) : (
        <p style={{ color: '#f0f0f0', opacity: 0.7 }}>No recent activity to display</p>
      )}
    </div>
  );
};

export default RecentActivity;
