import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, User, CreditCard, MessageCircle, LogOut, Settings } from 'react-feather';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { API_BASE_URL } from '../config/api';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, isAdmin } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      // Call logout endpoint
      const response = await fetch(`${API_BASE_URL}/api/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        // Clear local storage and update context
        logout();

        // Navigate to login page
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <div className="sidebar-header">
          <img src="/images/3.png" className="sidebar-logo" alt="SalesOptAI" />
        </div>
        <nav className="nav flex-column nav-menu">
          <Link 
            className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`} 
            to="/dashboard"
          >
            <Home /> Dashboard
          </Link>
          <Link 
            className={`nav-link ${location.pathname === '/account' ? 'active' : ''}`} 
            to="/account"
          >
            <User /> Account
          </Link>
          <Link 
            className={`nav-link ${location.pathname === '/billing' ? 'active' : ''}`} 
            to="/billing"
          >
            <CreditCard /> Billing
          </Link>
          <Link 
            className={`nav-link ${location.pathname === '/conversations' ? 'active' : ''}`} 
            to="/conversations"
          >
            <MessageCircle /> Conversations
          </Link>
          {isAdmin && (
            <Link 
              className={`nav-link ${location.pathname === '/system-settings' ? 'active' : ''}`} 
              to="/system-settings"
            >
              <Settings /> System Settings
            </Link>
          )}
        </nav>
        <div className="logout-link">
          <button 
            className="nav-link" 
            onClick={handleLogout} 
            style={{ 
              width: '100%', 
              background: 'none', 
              border: 'none', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '10px 15px',
              color: 'inherit'
            }}
          >
            <LogOut /> Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
