import { useState, useEffect, useCallback } from 'react';
import { Package, Clock, Activity } from 'react-feather';
import PageContainer from '../components/PageContainer';
import CreditBalance from './billing/CreditBalance';
import PaymentMethods from './billing/PaymentMethods';
import Subscription from './billing/Subscription';
import TransactionHistory from './billing/TransactionHistory';
import UsageHistory from './billing/UsageHistory';
import CancelAccount from './billing/CancelAccount';
import {
  fetchCreditBalance,
  fetchPaymentMethods,
  fetchTransactions,
  fetchSubscriptionDetails,
  fetchAvailableProducts,
  fetchUsageHistory
} from './billing/BillingUtils';
import './billing/billing.css';

const Billing = () => {
  const [creditBalance, setCreditBalance] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [usageData, setUsageData] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [subscribedProducts, setSubscribedProducts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const loadCreditBalance = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const data = await fetchCreditBalance(userId, token);
      if (data.credit_balance !== undefined) {
        setCreditBalance(data.credit_balance);
      }
    } catch (err) {
      console.error('Error loading credit balance:', err);
    }
  };

  const loadPaymentMethods = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const data = await fetchPaymentMethods(userId, token);
      if (data.payment_methods) {
        setPaymentMethods(data.payment_methods);
      }
    } catch (err) {
      console.error('Error loading payment methods:', err);
    }
  };

  const loadTransactions = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const data = await fetchTransactions(userId, token);
      if (data.transactions) {
        setTransactions(data.transactions);
      }
    } catch (err) {
      console.error('Error loading transactions:', err);
    }
  };

  const loadUsageHistory = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const data = await fetchUsageHistory(userId, token);
      if (data.usage) {
        setUsageData(data.usage);
      }
    } catch (err) {
      console.error('Error loading usage history:', err);
    }
  };

  const loadSubscriptionDetails = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const data = await fetchSubscriptionDetails(userId, token);
      if (data) {
        setSubscription(data.subscription);
        setSubscribedProducts(data.products);
        setTotalAmount(data.total_amount);
      }
    } catch (err) {
      console.error('Error loading subscription details:', err);
    }
  };

  const loadAvailableProducts = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const data = await fetchAvailableProducts(token);
      if (data.products) {
        setAvailableProducts(data.products);
      }
    } catch (err) {
      console.error('Error loading available products:', err);
    }
  };

  const loadInitialData = useCallback(async () => {
    try {
      setPageLoading(true);
      await Promise.all([
        loadCreditBalance(),
        loadPaymentMethods(),
        loadTransactions(),
        loadSubscriptionDetails(),
        loadAvailableProducts(),
        loadUsageHistory()
      ]);
    } catch (err) {
      setError('Failed to load billing information');
      console.error('Error loading billing information:', err);
    } finally {
      setPageLoading(false);
    }
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const handlePurchaseSuccess = (data) => {
    setCreditBalance(data.new_balance);
    loadTransactions(); // Refresh transactions after purchase
    loadPaymentMethods(); // Refresh payment methods after adding a new one
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
  };

  return (
    <PageContainer
      loading={pageLoading}
      loadingMessage="Loading billing information..."
      title="Billing & Subscription"
      subtitle="Manage your subscription and billing information"
    >
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {/* Credit Balance and Payment Methods Section */}
      <div className="billing-layout">
        <CreditBalance
          creditBalance={creditBalance}
          onPurchaseSuccess={handlePurchaseSuccess}
          onError={handleError}
          paymentMethods={paymentMethods}
        />
        
        <PaymentMethods
          paymentMethods={paymentMethods}
          onPaymentMethodsChange={loadPaymentMethods}
          onError={handleError}
        />
      </div>

      {/* Current Plan Section */}
      <h2 className="section-title">
        <Package className="section-icon" />
        Current Plan
      </h2>

      <Subscription
        subscription={subscription}
        subscribedProducts={subscribedProducts}
        availableProducts={availableProducts}
        totalAmount={totalAmount}
        onSubscriptionChange={loadSubscriptionDetails}
        onError={handleError}
      />

      {/* Billing History Section */}
      <h2 className="section-title">
        <Clock className="section-icon" />
        Billing History
      </h2>

      <TransactionHistory transactions={transactions} />

      {/* Usage History Section */}
      <h2 className="section-title">
        <Activity className="section-icon" />
        Usage History
      </h2>

      <UsageHistory usageData={usageData} />

      {/* Cancel Account Section */}
      <CancelAccount onError={handleError} />
    </PageContainer>
  );
};

export default Billing;
