import React, { useState } from 'react';
import { BarChart2 } from 'react-feather';
import { formatDuration, getSentimentEmoji } from './DashboardUtils';

const TimeframeButton = ({ timeframe, selectedTimeframe, label, onClick }) => (
  <button
    onClick={() => onClick(timeframe)}
    style={{
      background: selectedTimeframe === timeframe ? '#22cd94' : 'transparent',
      border: `1px solid ${selectedTimeframe === timeframe ? '#22cd94' : 'rgba(255, 255, 255, 0.2)'}`,
      borderRadius: '8px',
      padding: '0.5rem 1rem',
      color: 'white',
      cursor: 'pointer',
      transition: 'all 0.3s ease'
    }}
  >
    {label}
  </button>
);

const AIAnalysis = ({ analysis = {} }) => {
  const [selectedTimeframe, setSelectedTimeframe] = useState('daily');

  const renderAnalysisContent = () => {
    // Default data structure if analysis or timeframe data is undefined
    const defaultData = {
      total_interactions: 0,
      avg_duration: 0,
      sentiment_score: 0,
      ai_leads: 0,
      ai_closed_deals: 0,
      ai_prospects: 0
    };

    // Safely access data with fallback to default values
    const data = analysis[selectedTimeframe] || defaultData;

    return (
      <div style={{ color: '#f0f0f0' }}>
        <div style={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gap: '1rem',
          marginBottom: '1.5rem'
        }}>
          <div style={{ background: 'rgba(255, 255, 255, 0.05)', padding: '1rem', borderRadius: '8px' }}>
            <h4 style={{ margin: '0 0 0.5rem 0' }}>Total Interactions</h4>
            <p style={{ margin: 0, fontSize: '1.5rem' }}>{data.total_interactions}</p>
          </div>
          <div style={{ background: 'rgba(255, 255, 255, 0.05)', padding: '1rem', borderRadius: '8px' }}>
            <h4 style={{ margin: '0 0 0.5rem 0' }}>Average Duration</h4>
            <p style={{ margin: 0, fontSize: '1.5rem' }}>{formatDuration(data.avg_duration || 0)}</p>
          </div>
          <div style={{ background: 'rgba(255, 255, 255, 0.05)', padding: '1rem', borderRadius: '8px' }}>
            <h4 style={{ margin: '0 0 0.5rem 0' }}>Customer Sentiment</h4>
            <p style={{ margin: 0, fontSize: '1.5rem' }}>
              {getSentimentEmoji(data.sentiment_score || 0)} {((data.sentiment_score || 0) * 100).toFixed(0)}%
            </p>
          </div>
          <div style={{ background: 'rgba(255, 255, 255, 0.05)', padding: '1rem', borderRadius: '8px' }}>
            <h4 style={{ margin: '0 0 0.5rem 0' }}>AI Leads</h4>
            <p style={{ margin: 0, fontSize: '1.5rem' }}>{data.ai_leads || 0}</p>
          </div>
          <div style={{ background: 'rgba(255, 255, 255, 0.05)', padding: '1rem', borderRadius: '8px' }}>
            <h4 style={{ margin: '0 0 0.5rem 0' }}>AI Closed Deals</h4>
            <p style={{ margin: 0, fontSize: '1.5rem' }}>{data.ai_closed_deals || 0}</p>
          </div>
          <div style={{ background: 'rgba(255, 255, 255, 0.05)', padding: '1rem', borderRadius: '8px' }}>
            <h4 style={{ margin: '0 0 0.5rem 0' }}>AI Prospects</h4>
            <p style={{ margin: 0, fontSize: '1.5rem' }}>{data.ai_prospects || 0}</p>
          </div>
        </div>

        <div style={{ background: 'rgba(255, 255, 255, 0.05)', padding: '1.5rem', borderRadius: '8px' }}>
          <h4 style={{ margin: '0 0 1rem 0' }}>AI Insights</h4>
          <p style={{ margin: 0, fontSize: '1.1rem' }}>Coming Soon...</p>
        </div>
      </div>
    );
  };

  return (
    <div style={{
      background: 'rgba(255, 255, 255, 0.08)',
      borderRadius: '12px',
      padding: '1.5rem',
      marginBottom: '2rem'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1.5rem'
      }}>
        <h2 style={{
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          margin: 0
        }}>
          <BarChart2 size={24} />
          AI Analysis
        </h2>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <TimeframeButton
            timeframe="daily"
            selectedTimeframe={selectedTimeframe}
            label="Today"
            onClick={setSelectedTimeframe}
          />
          <TimeframeButton
            timeframe="weekly"
            selectedTimeframe={selectedTimeframe}
            label="This Week"
            onClick={setSelectedTimeframe}
          />
          <TimeframeButton
            timeframe="monthly"
            selectedTimeframe={selectedTimeframe}
            label="This Month"
            onClick={setSelectedTimeframe}
          />
          <TimeframeButton
            timeframe="custom"
            selectedTimeframe={selectedTimeframe}
            label="Custom"
            onClick={setSelectedTimeframe}
          />
        </div>
      </div>
      {renderAnalysisContent()}
    </div>
  );
};

export default AIAnalysis;
