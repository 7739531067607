import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API_BASE_URL } from '../config/api';

const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY 
  ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
  : Promise.reject(new Error('Stripe public key not found'));

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const SubscriptionFormContent = ({ email, onSuccess, onError, useBasicInfo }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [cardError, setCardError] = useState('');
  const [promotionCode, setPromotionCode] = useState('');
  const [promotionError, setPromotionError] = useState('');
  const [discountedPrices, setDiscountedPrices] = useState({ monthly: null, yearly: null });
  const [checkingPromotion, setCheckingPromotion] = useState(false);
  const [prices, setPrices] = useState({ monthly: null, yearly: null });
  const [loadingPrices, setLoadingPrices] = useState(true);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/stripe/subscription/prices`);
        if (!response.ok) {
          throw new Error('Failed to fetch prices');
        }
        const data = await response.json();
        setPrices({
          monthly: data.monthly_price,
          yearly: data.yearly_price
        });
      } catch (error) {
        console.error('Error fetching prices:', error);
        onError('Failed to load pricing information');
      } finally {
        setLoadingPrices(false);
      }
    };

    fetchPrices();
  }, [onError]);

  const validatePromotionCode = async () => {
    if (!promotionCode.trim()) return;
    
    setCheckingPromotion(true);
    setPromotionError('');
    try {
      // Get monthly discounted price
      const monthlyResponse = await fetch(`${API_BASE_URL}/api/stripe/subscription/calculate-price`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          plan_type: 'monthly',
          promotion_code: promotionCode
        })
      });

      // Get yearly discounted price
      const yearlyResponse = await fetch(`${API_BASE_URL}/api/stripe/subscription/calculate-price`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          plan_type: 'yearly',
          promotion_code: promotionCode
        })
      });

      if (!monthlyResponse.ok || !yearlyResponse.ok) {
        const errorData = await monthlyResponse.json();
        throw new Error(errorData.detail || 'Failed to validate promotion code');
      }

      const monthlyData = await monthlyResponse.json();
      const yearlyData = await yearlyResponse.json();

      setDiscountedPrices({
        monthly: monthlyData,
        yearly: yearlyData
      });
      setPromotionError('');
    } catch (error) {
      setPromotionError(error.message);
      setDiscountedPrices({ monthly: null, yearly: null });
    } finally {
      setCheckingPromotion(false);
    }
  };

  const handleCardChange = (event) => {
    if (event.error) {
      setCardError(event.error.message);
      onError(event.error.message);
    } else {
      setCardError('');
      onError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setCardError('');
    setPromotionError('');
    onError(null);

    if (!stripe || !elements) {
      onError('Payment system not initialized.');
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      onError('Card element not found.');
      setLoading(false);
      return;
    }

    try {
      const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { email }
      });

      if (paymentMethodError) throw new Error(paymentMethodError.message);

      const response = await fetch(`${API_BASE_URL}/api/stripe/subscription/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          payment_method_id: paymentMethod.id,
          email: email,
          plan_type: selectedPlan,
          promotion_code: promotionCode || undefined,
          use_basic_info: useBasicInfo
        })
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 422) {
          const errorDetail = data.detail?.[0]?.msg || data.detail || 'Invalid request data';
          if (errorDetail.includes('promotion')) {
            setPromotionError(errorDetail);
          }
          throw new Error(errorDetail);
        }
        throw new Error(data.detail || 'Failed to create subscription');
      }

      if (data.clientSecret) {
        const { error: confirmError } = await stripe.confirmCardPayment(data.clientSecret);
        if (confirmError) throw new Error(confirmError.message);
      }

      onSuccess({
        ...data,
        payment_method_id: paymentMethod.id,
        subscriptionId: data.subscription_id
      });
    } catch (error) {
      console.error('Subscription error:', error);
      if (!promotionError) {
        setCardError(error.message || 'An error occurred');
      }
      onError(error.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const getPriceDisplay = (planType) => {
    if (loadingPrices || checkingPromotion) {
      return (
        <div style={{ fontSize: '1.5rem', fontWeight: 600, color: '#333', marginBottom: '8px' }}>
          ...
        </div>
      );
    }

    const originalPrice = prices[planType];
    if (!originalPrice) return null;
    
    const suffix = planType === 'monthly' ? '/mo' : '/yr';
    const discountedPrice = discountedPrices[planType];

    if (discountedPrice) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
          <div style={{ 
            fontSize: '1rem', 
            color: '#666', 
            textDecoration: 'line-through' 
          }}>
            {formatCurrency(originalPrice)}{suffix}
          </div>
          <div style={{ 
            fontSize: '1.5rem', 
            fontWeight: 600, 
            color: '#ff4444'
          }}>
            {formatCurrency(discountedPrice.discounted_amount)}{suffix}
          </div>
        </div>
      );
    }

    return (
      <div style={{ fontSize: '1.5rem', fontWeight: 600, color: '#333', marginBottom: '8px' }}>
        {formatCurrency(originalPrice)}{suffix}
      </div>
    );
  };

  const getDiscountBadge = (planType) => {
    const discountedPrice = discountedPrices[planType];
    if (!discountedPrice) return null;
    
    const discountText = discountedPrice.discount_type === 'percentage'
      ? `${discountedPrice.discount_value}% off`
      : formatCurrency(discountedPrice.discount_value) + ' off';

    return (
      <div style={{
        position: 'absolute',
        top: '-12px',
        right: '-12px',
        background: '#ff4444',
        color: 'white',
        padding: '4px 8px',
        borderRadius: '12px',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        zIndex: 1
      }}>
        {discountText}
      </div>
    );
  };

  if (loadingPrices) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        <p>Loading pricing information...</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      {useBasicInfo && (
        <div style={{
          background: 'rgba(255, 255, 255, 0.1)',
          padding: '12px 16px',
          borderRadius: '8px',
          marginBottom: '24px',
          color: 'white',
          fontSize: '0.9rem',
          lineHeight: '1.4'
        }}>
          Note: Your account will be created with basic business information. You can enhance your AI assistant's knowledge later by providing additional business details.
        </div>
      )}

      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: '1fr 1fr', 
        gap: '16px',
        margin: '0 0 24px 0'
      }}>
        <div 
          onClick={() => setSelectedPlan('monthly')}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => e.key === 'Enter' && setSelectedPlan('monthly')}
          style={{
            background: 'white',
            border: `2px solid ${selectedPlan === 'monthly' ? '#22cd94' : '#e0e0e0'}`,
            borderRadius: '12px',
            padding: '16px',
            cursor: 'pointer',
            position: 'relative',
            textAlign: 'center',
            backgroundColor: selectedPlan === 'monthly' ? '#f0fff7' : 'white'
          }}
        >
          {getDiscountBadge('monthly')}
          <h4 style={{ margin: '0 0 8px 0', fontSize: '1rem', color: '#333' }}>Monthly Plan</h4>
          {getPriceDisplay('monthly')}
          <div style={{ fontSize: '0.85rem', color: '#666' }}>7-day free trial</div>
          {selectedPlan === 'monthly' && (
            <div style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              background: '#22cd94',
              color: 'white',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              fontWeight: 'bold'
            }}>✓</div>
          )}
        </div>

        <div 
          onClick={() => setSelectedPlan('yearly')}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => e.key === 'Enter' && setSelectedPlan('yearly')}
          style={{
            background: 'white',
            border: `2px solid ${selectedPlan === 'yearly' ? '#22cd94' : '#e0e0e0'}`,
            borderRadius: '12px',
            padding: '16px',
            cursor: 'pointer',
            position: 'relative',
            textAlign: 'center',
            backgroundColor: selectedPlan === 'yearly' ? '#f0fff7' : 'white'
          }}
        >
          {getDiscountBadge('yearly')}
          <h4 style={{ margin: '0 0 8px 0', fontSize: '1rem', color: '#333' }}>Annual Plan</h4>
          {getPriceDisplay('yearly')}
          <div style={{ color: '#22cd94', fontWeight: 500, fontSize: '0.9rem', marginBottom: '8px' }}>
            Save 17%
          </div>
          <div style={{ fontSize: '0.85rem', color: '#666' }}>7-day free trial</div>
          {selectedPlan === 'yearly' && (
            <div style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              background: '#22cd94',
              color: 'white',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              fontWeight: 'bold'
            }}>✓</div>
          )}
        </div>
      </div>

      <div style={{ marginTop: '24px' }}>
        <p style={{ 
          fontSize: '1rem', 
          color: 'white', 
          marginBottom: '16px', 
          textAlign: 'center',
          fontWeight: '500',
          lineHeight: '1.5',
          background: 'rgba(255, 255, 255, 0.1)',
          padding: '12px',
          borderRadius: '8px'
        }}>
          Start your 7-day free trial today. Your card won't be charged until the trial ends.
        </p>

        {/* Promotion Code Input */}
        <div style={{ marginBottom: '16px' }}>
          <label style={{ 
            display: 'block', 
            marginBottom: '8px',
            color: 'white'
          }}>
            Have a promotion code?
          </label>
          <div style={{ display: 'flex', gap: '8px' }}>
            <input
              type="text"
              value={promotionCode}
              onChange={(e) => {
                setPromotionCode(e.target.value);
                setPromotionError('');
                setDiscountedPrices({ monthly: null, yearly: null });
              }}
              placeholder="Enter promotion code"
              style={{
                flex: 1,
                padding: '12px',
                borderRadius: '8px',
                border: '1px solid #e0e0e0',
                fontSize: '16px',
                backgroundColor: 'white'
              }}
            />
            <button
              type="button"
              onClick={validatePromotionCode}
              disabled={!promotionCode.trim() || checkingPromotion}
              style={{
                padding: '12px 24px',
                borderRadius: '8px',
                border: 'none',
                backgroundColor: '#22cd94',
                color: 'white',
                cursor: 'pointer',
                fontSize: '16px',
                opacity: (!promotionCode.trim() || checkingPromotion) ? 0.7 : 1
              }}
            >
              {checkingPromotion ? 'Validating...' : 'Apply'}
            </button>
          </div>
          {promotionError && (
            <div style={{
              color: '#e53e3e',
              backgroundColor: 'rgba(229, 62, 62, 0.1)',
              padding: '8px',
              borderRadius: '4px',
              marginTop: '8px',
              fontSize: '0.875rem'
            }}>
              {promotionError}
            </div>
          )}
        </div>
        
        <div style={{
          background: 'white',
          padding: '16px',
          borderRadius: '12px',
          border: '1px solid #e0e0e0'
        }}>
          <CardElement 
            onChange={handleCardChange}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#e53e3e',
                },
              },
              hidePostalCode: true,
              hideIcon: true,
              hideError: true
            }}
          />
        </div>

        {/* Display card error if present */}
        {cardError && (
          <div style={{
            color: '#e53e3e',
            backgroundColor: 'rgba(229, 62, 62, 0.1)',
            padding: '12px',
            borderRadius: '8px',
            marginTop: '12px',
            fontSize: '0.875rem'
          }}>
            {cardError}
          </div>
        )}
      </div>

      <button 
        type="submit" 
        disabled={!stripe || loading}
        style={{
          background: '#22cd94',
          border: 'none',
          borderRadius: '12px',
          padding: '12px 24px',
          color: 'white',
          cursor: loading ? 'not-allowed' : 'pointer',
          fontSize: '1rem',
          fontWeight: '600',
          width: '100%',
          marginTop: '24px',
          opacity: loading ? 0.7 : 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px'
        }}
      >
        {loading ? (
          <>
            <span className="loading-spinner"></span>
            Processing...
          </>
        ) : (
          'Start Free Trial'
        )}
      </button>
    </form>
  );
};

const SubscriptionForm = (props) => {
  if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        <p style={{ color: '#e53e3e' }}>Payment system is currently unavailable.</p>
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <SubscriptionFormContent {...props} />
    </Elements>
  );
};

export default SubscriptionForm;
