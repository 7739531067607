import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config/api';
import PageContainer from '../components/PageContainer';
import './conversations.css';

const Conversations = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleLogout = () => {
    console.log('Logout clicked');
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  const fetchConversations = async () => {
    try {
      console.log('Fetching conversations...');
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/conversations`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Response:', response);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Data:', data);
      if (data.status === 'success') {
        setConversations(data.conversations);
      } else {
        setError('Failed to fetch conversations');
      }
    } catch (err) {
      console.error('Error fetching conversations:', err);
      setError(`Error connecting to server: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchMessages = async (conversationId) => {
    try {
      console.log('Fetching messages...');
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/conversations/${conversationId}/messages`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Messages Response:', response);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Messages Data:', data);
      if (data.status === 'success') {
        setMessages(data.messages);
      } else {
        setError('Failed to fetch messages');
      }
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError(`Error connecting to server: ${err.message}`);
    }
  };

  const handleConversationClick = async (conversation) => {
    setSelectedConversation(conversation);
    await fetchMessages(conversation.id);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const formatDuration = (seconds) => {
    if (!seconds) return '-';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <PageContainer
      loading={loading}
      loadingMessage="Loading conversations..."
      title="Conversations"
      subtitle="Manage your AI-powered communications"
      onLogout={handleLogout}
    >
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <div className="logs-section">
        <h2 className="section-title">Conversation History</h2>
        <table className="conversation-table">
          <thead>
            <tr>
              <th>Source</th>
              <th>Thread ID</th>
              <th>Customer</th>
              <th>Phone</th>
              <th>Start Time</th>
              <th>Duration</th>
              <th>Status</th>
              <th>Recording</th>
            </tr>
          </thead>
          <tbody>
            {conversations.map((conversation) => (
              <tr 
                key={conversation.id}
                onClick={() => handleConversationClick(conversation)}
                className={`conversation-row ${selectedConversation?.id === conversation.id ? 'selected' : ''}`}
              >
                <td data-label="Source">{conversation.source}</td>
                <td data-label="Thread ID">{conversation.external_id}</td>
                <td data-label="Customer">{conversation.customer_name || 'Unknown'}</td>
                <td data-label="Phone">{conversation.customer_phone || '-'}</td>
                <td data-label="Start Time">{formatDate(conversation.start_time)}</td>
                <td data-label="Duration">{formatDuration(conversation.duration)}</td>
                <td data-label="Status">{conversation.status}</td>
                <td data-label="Recording">
                  {conversation.recording_url ? (
                    <a 
                      href={conversation.recording_url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="recording-link"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Listen
                    </a>
                  ) : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedConversation && (
        <div className="messages-section">
          <h2 className="section-title">Conversation Details</h2>
          
          {selectedConversation.summary && (
            <div className="summary-section">
              <h3 className="summary-title">Summary</h3>
              <p className="summary-content">
                {selectedConversation.summary}
              </p>
            </div>
          )}

          <h3 className="messages-title">Messages</h3>
          <div className="messages-container">
            {messages.map((message) => (
              <div 
                key={message.id}
                className={`message ${message.role}`}
              >
                <div className="message-timestamp">
                  {message.role} - {formatDate(message.timestamp)}
                </div>
                <div>{message.message}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default Conversations;
