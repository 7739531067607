import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import AuthLayout from './AuthLayout';

const ProtectedRoute = () => {
  const { userId } = useContext(UserContext);

  useEffect(() => {
    console.log('ProtectedRoute - Current userId:', userId);
    console.log('ProtectedRoute - localStorage userId:', localStorage.getItem('userId'));
  }, [userId]);

  // If there's no userId, redirect to login
  if (!userId) {
    console.log('ProtectedRoute - Redirecting to login due to no userId');
    return <Navigate to="/login" replace />;
  }

  // If we have a userId, render the protected content within AuthLayout
  return <AuthLayout />;
};

export default ProtectedRoute;
