import React from 'react';

const LoadingSpinner = ({ message = 'Loading...' }) => (
  <div style={{
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '12px',
    padding: '2rem',
    textAlign: 'center'
  }}>
    <div className="loading-spinner" style={{
      border: '4px solid rgba(255, 255, 255, 0.1)',
      borderTop: '4px solid #ffffff',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      animation: 'spin 1s linear infinite',
      margin: '0 auto 1rem auto'
    }}></div>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <p>{message}</p>
  </div>
);

export default LoadingSpinner;
